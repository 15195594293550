import React from 'react';

import { injectStore, useStore } from 'application/store';

import Component from './SignIn';
import { create as createStore } from './store';

injectStore('signIn', createStore());

const Module: React.FC = () => {
  const store = useStore();
  const { containers } = store;
  const { signIn: componentStore } = containers;

  return <Component store={componentStore} />;
};

export default Module;
