import { types } from 'mobx-state-tree';

import { Store as AdminStore, store as adminStore } from './admin/store';
import { Store as StoresStore, store as storesStore } from './stores/store';
import { Store as ProductsStore, store as productsStore } from './products/store';
import { Store as SuppliersStore, store as suppliersStore } from './suppliers/store';
import { Store as OrdersStore, store as ordersStore } from './orders/store';
import { Store as SupplierOrdersStore, store as supplierOrdersStore } from './supplierOrders/store';
import { Store as ArticlesStore, store as articlesStore } from './articles/store';
import { Store as ProductCategoriesStore, store as productCategoriesStore } from './productCategories/store';
import { Store as PostponedOrdersStore, store as postponedOrdersStore } from './postponedOrders/store';
import { Store as CloudStore, store as cloudStore } from './cloud/store';

export const Store = types.model({
  admin: AdminStore,
  stores: StoresStore,
  products: ProductsStore,
  suppliers: SuppliersStore,
  orders: OrdersStore,
  supplierOrders: SupplierOrdersStore,
  articles: ArticlesStore,
  productCategories: ProductCategoriesStore,
  postponedOrders: PostponedOrdersStore,
  cloud: CloudStore,
});

export const store = Store.create({
  admin: adminStore,
  stores: storesStore,
  products: productsStore,
  suppliers: suppliersStore,
  orders: ordersStore,
  supplierOrders: supplierOrdersStore,
  articles: articlesStore,
  productCategories: productCategoriesStore,
  postponedOrders: postponedOrdersStore,
  cloud: cloudStore,
});

export default store;
