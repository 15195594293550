import { colors } from 'components/constants';

export default {
  size: 40,
  bottom: 16,
  right: 16,
  borderRadius: 6,
  colors: {
    normal: colors.white100,
    hover: colors.white100,
  },
  backgroundColors: {
    normal: colors.primary50,
    hover: colors.primary60,
  },
};
