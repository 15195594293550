import { types, Instance } from 'mobx-state-tree';

export * from './admin/models';
export * from './stores/models';
export * from './products/models';
export * from './suppliers/models';
export * from './orders/models';
export * from './supplierOrders/models';
export * from './articles/models';
export * from './productCategories/models';

export const Paging = types.model({
  limit: types.number,
  offset: types.number,
  total: types.number,
  next: types.boolean,
});

export interface IPaging extends Instance<typeof Paging> {}
