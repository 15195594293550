import { types, Instance } from 'mobx-state-tree';
import { Supplier } from '../suppliers/models';

export const Category = types.model({
  id: types.maybeNull(types.number),
  key: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});

export const HistoryItem = types.model({
  id: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});

const History = types.array(HistoryItem);

export const Product = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  photos: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  supplierId: types.maybeNull(types.number),
  categoryId: types.maybeNull(types.number),
  supplier: types.maybeNull(Supplier),
  category: types.maybeNull(Category),
  selected: types.maybeNull(types.boolean),
  last3Orders: types.maybeNull(History),
});

export interface IProduct extends Instance<typeof Product> {}

const Products = types.array(Product);

export interface IProducts extends Instance<typeof Products> {}

export const GetProducts = types.model({
  list: types.maybeNull(Products),
  next: types.maybeNull(types.boolean),
  total: types.maybeNull(types.number),
});

export interface IGetProducts extends Instance<typeof GetProducts> {}

export const ProductsFilter = types.model({
  supplierId: types.maybeNull(types.string),
});

export interface IProductsFilter extends Instance<typeof ProductsFilter> {}
