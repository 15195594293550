import { AxiosError } from 'axios';

import { storageKeys } from './constants';

export function setTokens(data: { accessToken: string; refreshToken: string }) {
  localStorage.setItem(storageKeys.accessToken, data.accessToken);
  localStorage.setItem(storageKeys.refreshToken, data.refreshToken);
}

export function removeTokens() {
  localStorage.removeItem(storageKeys.accessToken);
  localStorage.removeItem(storageKeys.refreshToken);
}

export function getToken(key: string) {
  return localStorage.getItem(key);
}

export function getAccessToken() {
  return getToken(storageKeys.accessToken);
}

export function getRefreshToken() {
  return getToken(storageKeys.refreshToken);
}

export function getTokenExpTime(key: string) {
  try {
    const token = localStorage.getItem(key);
    if (!token) return 0;
    const data = JSON.parse(atob(token.split('.')[1]));
    if (!data.exp) return 0;
    return data.exp * 1000 - Date.now();
  } catch (error) {
    return 0;
  }
}

export function getAccessTokenExpTime() {
  return getTokenExpTime(storageKeys.accessToken);
}

export function getRefreshTokenExpTime() {
  return getTokenExpTime(storageKeys.refreshToken);
}

export type ApiError = Readonly<{
  message?: string;
}> &
  AxiosError &
  Error;

export function getErrorMessage(error: ApiError) {
  if (error.response) {
    if (error.response.data) {
      if (typeof error.response.data === 'string') return error.response.data;
      return JSON.stringify(error.response.data);
    } else if (error.response.statusText) {
      return error.response.statusText;
    }
  }
  return error.message;
}
