import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { CancelTokenSource } from 'axios';
import { getCancelToken } from '../../axios';

let cancelGetPostponedOrders: CancelTokenSource = null;
export const Store = types.model().actions(() => ({
  getPostponedOrders: flow(function* request(params) {
    cancelGetPostponedOrders = getCancelToken(cancelGetPostponedOrders);
    const response = yield requests.getPostponedOrders(params, cancelGetPostponedOrders);
    return response?.data;
  }),

  addPostponedOrder: flow(function* request(data) {
    const response = yield requests.addPostponedOrder(data);
    return response?.data;
  }),

  createOrder: flow(function* request(data) {
    const response = yield requests.createOrder(data);
    return response?.data;
  }),

  createOrders: flow(function* request(data) {
    const response = yield requests.createOrders(data);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
