import { types, flow } from 'mobx-state-tree';

import requests from './requests';

export const Store = types.model().actions(() => ({
  signIn: flow(function* request(data) {
    const response = yield requests.signIn(data);
    return response?.data;
  }),

  signInGoogle: flow(function* request(data) {
    const response = yield requests.signInGoogle(data);
    return response?.data;
  }),

  getProfile: flow(function* request() {
    const response = yield requests.getProfile();
    return response?.data;
  }),

  getRoles: flow(function* request() {
    const response = yield requests.getRoles();
    return response?.data;
  }),

  createRole: flow(function* request(data) {
    const response = yield requests.createRole(data);
    return response?.data;
  }),

  updateRole: flow(function* request(id, data) {
    const response = yield requests.updateRole(id, data);
    return response?.data;
  }),

  removeRole: flow(function* request(id) {
    const response = yield requests.removeRole(id);
    return response?.data;
  }),

  getOperators: flow(function* request() {
    const response = yield requests.getOperators();
    return response?.data;
  }),

  getOperator: flow(function* request(id) {
    const response = yield requests.getOperator(id);
    return response?.data;
  }),

  createOperator: flow(function* request(data) {
    const response = yield requests.createOperator(data);
    return response?.data;
  }),

  updateOperator: flow(function* request(id, data) {
    const response = yield requests.updateOperator(id, data);
    return response?.data;
  }),

  removeOperator: flow(function* request(id) {
    const response = yield requests.removeOperator(id);
    return response?.data;
  }),

  getLogs: flow(function* request(params) {
    const response = yield requests.getLogs(params);
    return response?.data;
  }),

  removeOrders: flow(function* request() {
    const response = yield requests.removeOrders();
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
