import { types, flow } from 'mobx-state-tree';

import requests from './requests';

export const Store = types.model().actions(() => ({
  previewSupplierOrder: flow(function* request(data) {
    const response = yield requests.previewSupplierOrder(data);
    return response?.data;
  }),

  getSupplierOrders: flow(function* request(params) {
    const response = yield requests.getSupplierOrders(params);
    return response?.data;
  }),

  getSupplierOrder: flow(function* request(id) {
    const response = yield requests.getSupplierOrder(id);
    return response?.data;
  }),

  createSupplierOrder: flow(function* request(data) {
    const response = yield requests.createSupplierOrder(data);
    return response?.data;
  }),

  createSupplierOrders: flow(function* request(data) {
    const response = yield requests.createSupplierOrders(data);
    return response?.data;
  }),

  updateSupplierOrder: flow(function* request(id, data) {
    const response = yield requests.updateSupplierOrder(id, data);
    return response?.data;
  }),

  updateSupplierOrders: flow(function* request(id, data) {
    const response = yield requests.updateSupplierOrders(id, data);
    return response?.data;
  }),

  updateSupplierOrdersStatus: flow(function* request(id, status) {
    const response = yield requests.updateSupplierOrdersStatus(id, status);
    return response?.data;
  }),

  removeSupplierOrder: flow(function* request(id) {
    const response = yield requests.removeSupplierOrder(id);
    return response?.data;
  }),

  getSupplierOrdersGroups: flow(function* request(params) {
    const response = yield requests.getSupplierOrdersGroups(params);
    return response?.data;
  }),

  getSupplierOrdersGroup: flow(function* request(id) {
    const response = yield requests.getSupplierOrdersGroup(id);
    return response?.data;
  }),

  getSupplierOrdersDraft: flow(function* request() {
    const response = yield requests.getSupplierOrdersDraft();
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
