import AxiosMockAdapter from 'axios-mock-adapter';

import { getApiInstance } from './instance';

import adminMockAdapter from './admin/mock';
import supplierOrdersMockAdapter from './supplierOrders/mock';
import articlesMockAdapter from './articles/mock';

export default function mockAdapter() {
  const axiosMockInstance = new AxiosMockAdapter(getApiInstance(), { delayResponse: 1000 });

  adminMockAdapter(axiosMockInstance);
  supplierOrdersMockAdapter(axiosMockInstance);
  articlesMockAdapter(axiosMockInstance);
}
