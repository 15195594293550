import { types, Instance } from 'mobx-state-tree';

export const Article = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
});

export interface IArticle extends Instance<typeof Article> {}
