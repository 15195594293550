import React, { useContext } from 'react';

import Span from 'components/atoms/Span';
import { LayoutContentProps } from './types';
import { StyledLayoutContent, StyledLayoutContentVersion, StyledLayoutSection } from './style';
import { LayoutContext } from './context';

const LayoutContent: React.FC<LayoutContentProps> = (props) => {
  const { width, children } = props;

  const { version } = useContext(LayoutContext);
  return (
    <StyledLayoutContent data-component="LayoutContent">
      <StyledLayoutSection sGrow sWidth={width}>
        {children}
      </StyledLayoutSection>
      <StyledLayoutContentVersion>
        <Span size="xs" color="primary">
          {version}
        </Span>
      </StyledLayoutContentVersion>
    </StyledLayoutContent>
  );
};

export default LayoutContent;
