import React from 'react';

import { IconProps } from './types';
import IconPrimitive from './_internal/IconPrimitive';

const IconEyeOpen: React.FC<IconProps> = (props) => {
  return (
    <IconPrimitive {...props} viewBox={24}>
      <path d="M11.9998,5 C16.4627,5 20.4811,7.79685 23.6075,11.4013 C24.1339,12.0377 24.1333,12.9952 23.5907,13.6493 C20.4809,17.2189 16.467,20 12.0063,20 C7.54597,20 3.52576,17.2183 0.395693,13.6322 C-0.133059,12.9968 -0.13462,12.0381 0.407417,11.3827 C3.52066,7.79533 7.53738,5 11.9998,5 Z M11.9998,7.01043 C8.29092,7.01043 4.73751,9.4237 1.9905,12.5133 C4.75182,15.596 8.30296,17.9896 12.0063,17.9896 C15.7157,17.9896 19.2661,15.589 22.0098,12.5153 C19.252,9.41695 15.7029,7.01043 11.9998,7.01043 Z M12.0063,8.70893 C13.9596,8.70893 15.5448,10.4073 15.5448,12.5 C15.5448,14.5927 13.9596,16.2911 12.0063,16.2911 C10.0531,16.2911 8.4679,14.5927 8.4679,12.5 C8.4679,10.4073 10.0531,8.70893 12.0063,8.70893 Z M12.0063,10.7194 C11.0894,10.7194 10.3444,11.5176 10.3444,12.5 C10.3444,13.4824 11.0894,14.2806 12.0063,14.2806 C12.9232,14.2806 13.6683,13.4824 13.6683,12.5 C13.6683,11.5176 12.9233,10.7194 12.0063,10.7194 Z" />
    </IconPrimitive>
  );
};

export default IconEyeOpen;
