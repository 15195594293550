import { lazy } from 'react';

import globals from 'application/globals';

const components = {
  home: lazy(() => import('pages/Home')),
  notFound: lazy(() => import('pages/NotFound')),
  administration: {
    main: lazy(() => import('pages/Administration/Main')),
    roles: {
      main: lazy(() => import('pages/Administration/Roles/Main')),
    },
    operators: {
      main: lazy(() => import('pages/Administration/Operators/Main')),
      create: lazy(() => import('pages/Administration/Operators/Create')),
      item: lazy(() => import('pages/Administration/Operators/Item')),
    },
    logs: {
      main: lazy(() => import('pages/Administration/Logs/Main')),
    },
  },
  business: {
    main: lazy(() => import('pages/Business/Main')),
    stores: {
      main: lazy(() => import('pages/Business/Stores/Main')),
      create: lazy(() => import('pages/Business/Stores/Create')),
      item: lazy(() => import('pages/Business/Stores/Item')),
    },
    suppliers: {
      main: lazy(() => import('pages/Business/Suppliers/Main')),
      create: lazy(() => import('pages/Business/Suppliers/Create')),
      item: lazy(() => import('pages/Business/Suppliers/Item')),
    },
    products: {
      main: lazy(() => import('pages/Business/Products/Main')),
      create: lazy(() => import('pages/Business/Products/Create')),
      item: lazy(() => import('pages/Business/Products/Item')),
    },
    warehouse: {
      main: lazy(() => import('pages/Business/Warehouse/Main')),
    },
  },
  orders: {
    main: lazy(() => import('pages/Orders/Main')),
    storeOrders: {
      main: lazy(() => import('pages/Orders/StoreOrders/Main')),
      create: lazy(() => import('pages/Orders/StoreOrders/Create')),
      item: lazy(() => import('pages/Orders/StoreOrders/Item')),
    },
    orderManagement: {
      main: lazy(() => import('pages/Orders/OrderManagement/Main')),
      create: lazy(() => import('pages/Orders/OrderManagement/Create')),
      item: lazy(() => import('pages/Orders/OrderManagement/Item')),
    },
  },
  content: {
    main: lazy(() => import('pages/Content/Main')),
    articles: {
      main: lazy(() => import('pages/Content/Articles/Main')),
      item: lazy(() => import('pages/Content/Articles/Item')),
    },
    articlesManagement: {
      main: lazy(() => import('pages/Content/ArticlesManagement/Main')),
      create: lazy(() => import('pages/Content/ArticlesManagement/Create')),
      item: lazy(() => import('pages/Content/ArticlesManagement/Item')),
    },
  },
};

export const routes = [
  {
    ...globals.pages.home,
    component: components.home,
  },

  {
    ...globals.pages.administration.main,
    component: components.administration.main,
  },

  {
    ...globals.pages.administration.roles.main,
    component: components.administration.roles.main,
  },

  {
    ...globals.pages.administration.operators.create,
    component: components.administration.operators.create,
  },
  {
    ...globals.pages.administration.operators.item,
    component: components.administration.operators.item,
  },
  {
    ...globals.pages.administration.operators.main,
    component: components.administration.operators.main,
  },
  {
    ...globals.pages.business.main,
    component: components.business.main,
  },

  {
    ...globals.pages.business.stores.create,
    component: components.business.stores.create,
  },
  {
    ...globals.pages.business.stores.item,
    component: components.business.stores.item,
  },
  {
    ...globals.pages.business.stores.main,
    component: components.business.stores.main,
  },

  {
    ...globals.pages.business.warehouse.main,
    component: components.business.warehouse.main,
  },

  {
    ...globals.pages.administration.logs.main,
    component: components.administration.logs.main,
  },

  {
    ...globals.pages.business.suppliers.create,
    component: components.business.suppliers.create,
  },
  {
    ...globals.pages.business.suppliers.item,
    component: components.business.suppliers.item,
  },
  {
    ...globals.pages.business.suppliers.main,
    component: components.business.suppliers.main,
  },

  {
    ...globals.pages.business.products.create,
    component: components.business.products.create,
  },
  {
    ...globals.pages.business.products.item,
    component: components.business.products.item,
  },
  {
    ...globals.pages.business.products.main,
    component: components.business.products.main,
  },

  {
    ...globals.pages.orders.main,
    component: components.orders.main,
  },

  {
    ...globals.pages.orders.storeOrders.create,
    component: components.orders.storeOrders.create,
  },
  {
    ...globals.pages.orders.storeOrders.item,
    component: components.orders.storeOrders.item,
  },
  {
    ...globals.pages.orders.storeOrders.main,
    component: components.orders.storeOrders.main,
  },

  {
    ...globals.pages.orders.orderManagement.create,
    component: components.orders.orderManagement.create,
  },
  {
    ...globals.pages.orders.orderManagement.item,
    component: components.orders.orderManagement.item,
  },
  {
    ...globals.pages.orders.orderManagement.main,
    component: components.orders.orderManagement.main,
  },

  {
    ...globals.pages.content.main,
    component: components.content.main,
  },
  {
    ...globals.pages.content.articles.item,
    component: components.content.articles.item,
  },
  {
    ...globals.pages.content.articles.main,
    component: components.content.articles.main,
  },
  {
    ...globals.pages.content.articlesManagement.create,
    component: components.content.articlesManagement.create,
  },
  {
    ...globals.pages.content.articlesManagement.item,
    component: components.content.articlesManagement.item,
  },
  {
    ...globals.pages.content.articlesManagement.main,
    component: components.content.articlesManagement.main,
  },

  {
    ...globals.pages.notFound,
    component: components.notFound,
  },
];
