import { types, Instance } from 'mobx-state-tree';

const WorkingDay = types.model({
  id: types.maybeNull(types.number),
  supplierId: types.maybeNull(types.number),
  day: types.maybeNull(types.number),
  orderTimeStart: types.maybeNull(types.string),
  orderTimeStop: types.maybeNull(types.string),
});

export interface IWorkingDay extends Instance<typeof WorkingDay> {}

export const WorkingDays = types.array(WorkingDay);

export interface IWorkingDays extends Instance<typeof WorkingDays> {}

export const Supplier = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  entityCode: types.maybeNull(types.string),
  contact: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  workingDays: types.maybeNull(WorkingDays),
});

export interface ISupplier extends Instance<typeof Supplier> {}

export const Suppliers = types.array(Supplier);

export interface ISuppliers extends Instance<typeof Suppliers> {}

export const GetSuppliers = types.model({
  list: types.maybeNull(Suppliers),
  next: types.maybeNull(types.boolean),
  total: types.maybeNull(types.number),
});

export interface IGetSuppliers extends Instance<typeof GetSuppliers> {}

export const SuppliersFilter = types.model({
  workingDay: types.maybeNull(types.string),
});

export interface ISuppliersFilter extends Instance<typeof SuppliersFilter> {}
