import { types, flow } from 'mobx-state-tree';

import requests from './requests';

export const Store = types.model().actions(() => ({
  getOrders: flow(function* request(params) {
    const response = yield requests.getOrders(params);
    return response?.data;
  }),

  getOrder: flow(function* request(id) {
    const response = yield requests.getOrder(id);
    return response?.data;
  }),

  createOrder: flow(function* request(data) {
    const response = yield requests.createOrder(data);
    return response?.data;
  }),

  updateOrder: flow(function* request(id, data) {
    const response = yield requests.updateOrder(id, data);
    return response?.data;
  }),

  removeOrder: flow(function* request(id) {
    const response = yield requests.removeOrder(id);
    return response?.data;
  }),

  updateOrderItems: flow(function* request(data) {
    const response = yield requests.updateOrderItems(data);
    return response?.data;
  }),

  getProductOrdersHistory: flow(function* request(id, data) {
    const response = yield requests.getProductOrdersHistory(id, data);
    return response?.data;
  }),

  checkOrder: flow(function* request(data) {
    const response = yield requests.checkOrder(data);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
