import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { CancelTokenSource } from 'axios';
import { getCancelToken } from '../../axios';

let cancelGetProducts: CancelTokenSource = null;

export const Store = types.model().actions(() => ({
  getProducts: flow(function* request(params) {
    cancelGetProducts = getCancelToken(cancelGetProducts);
    const response = yield requests.getProducts(params, cancelGetProducts);
    return response?.data;
  }),

  getProduct: flow(function* request(id) {
    const response = yield requests.getProduct(id);
    return response?.data;
  }),

  createProduct: flow(function* request(data) {
    const response = yield requests.createProduct(data);
    return response?.data;
  }),

  updateProduct: flow(function* request(id, data) {
    const response = yield requests.updateProduct(id, data);
    return response?.data;
  }),

  removeProduct: flow(function* request(id) {
    const response = yield requests.removeProduct(id);
    return response?.data;
  }),

  removeProducts: flow(function* request(data) {
    const response = yield requests.removeProducts(data);
    return response?.data;
  }),

  updateProducts: flow(function* request(data) {
    const response = yield requests.updateProducts(data);
    return response?.data;
  }),

  getBalance: flow(function* request(id, params) {
    const response = yield requests.getBalance(id, params);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
