export const suppliersOrdersResponse = {
  list: [
    {
      id: 8,
      supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
      sum: 3601800,
      amount: 26,
      status: 'created',
      supplierId: 6,
      createdAt: '2022-12-28T18:51:30.959Z',
      num: 3,
      supplierOrderItems: [
        {
          id: 506,
          product: {
            id: 506,
            code: '1670754330640',
            title: 'Сергеевская с/к порц 0,17кг вес упак (Ч)',
            description: '',
            price: 40000,
            photos: '',
            createdAt: '2022-12-11T10:25:30.640Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 6,
          orderItems: [
            {
              id: 62,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 1,
            },
            {
              id: 75,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 2,
            },
            {
              id: 84,
              orderId: 16,
              store: {
                id: 2,
                address: 'г Санкт-Петербург, г Колпино',
                lat: '59.746632',
                lng: '30.595469',
                title: 'Тестовый Магазин 2',
                phone: '+76464646546',
                ownerId: 4,
              },
              amount: 3,
            },
          ],
        },
        {
          id: 505,
          product: {
            id: 505,
            code: '1670754330636',
            title: 'Сервелат Президентский с/к вес упак (Ч)',
            description: '',
            price: 8000,
            photos: '',
            createdAt: '2022-12-11T10:25:30.636Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 18,
          orderItems: [
            {
              id: 63,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 5,
            },
            {
              id: 90,
              orderId: 17,
              store: {
                id: 1,
                address: 'г Санкт-Петербург, г Пушкин',
                lat: '59.72034',
                lng: '30.404469',
                title: 'Тестовый Магазин',
                phone: '+78005553535',
                ownerId: 2,
              },
              amount: 7,
            },
            {
              id: 85,
              orderId: 16,
              store: {
                id: 2,
                address: 'г Санкт-Петербург, г Колпино',
                lat: '59.746632',
                lng: '30.595469',
                title: 'Тестовый Магазин 2',
                phone: '+76464646546',
                ownerId: 4,
              },
              amount: 6,
            },
          ],
        },
        {
          id: 504,
          product: {
            id: 504,
            code: '1670754330632',
            title: 'ПЕППЕРОНИ колбаса с/к (0,2гр шт.) (ДОБРОГОСТ)',
            description: '',
            price: 10000,
            photos: '',
            createdAt: '2022-12-11T10:25:30.632Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 9,
          orderItems: [
            {
              id: 64,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 9,
            },
          ],
        },
        {
          id: 503,
          product: {
            id: 503,
            code: '1670754330627',
            title: 'МОСКВИЧКА колбаса с/к (0,2гр шт.) охл (ДОБРОГОСТ) БЕЗ ВОЗВРАТОВ',
            description: '',
            price: 50000,
            photos: '',
            createdAt: '2022-12-11T10:25:30.627Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 23,
          orderItems: [
            {
              id: 65,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 11,
            },
            {
              id: 76,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 12,
            },
          ],
        },
        {
          id: 502,
          product: {
            id: 502,
            code: '1670754330622',
            title: 'МИЛАНСКАЯ колбаса с/к (0,150г. шт)(ДОБРОГОСТ) вес упак',
            description: '',
            price: 0,
            photos: '',
            createdAt: '2022-12-11T10:25:30.622Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 70,
          orderItems: [
            {
              id: 66,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 70,
            },
          ],
        },
        {
          id: 498,
          product: {
            id: 498,
            code: '1670754330604',
            title: 'Копчетто колбаски Мясная гирлянда чили с/к вес упак (Ч)',
            description: '',
            price: 0,
            photos: '',
            createdAt: '2022-12-11T10:25:30.604Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 88,
          orderItems: [
            {
              id: 67,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 88,
            },
          ],
        },
        {
          id: 258,
          product: {
            id: 258,
            code: '1670754329228',
            title: 'Без свинины сервелат в/к (срез 350г) в/у вес упак',
            description: '',
            price: 28000,
            photos: '',
            createdAt: '2022-12-11T10:25:29.228Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 11,
          orderItems: [
            {
              id: 68,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 11,
            },
          ],
        },
        {
          id: 257,
          product: {
            id: 257,
            code: '1670754329222',
            title: 'Астраханская колбаса (500гр.шт.) п/к мгс вес упак.',
            description: '',
            price: 0,
            photos: '',
            createdAt: '2022-12-11T10:25:29.222Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 129,
          orderItems: [
            {
              id: 69,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 22,
            },
            {
              id: 80,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 7,
            },
            {
              id: 86,
              orderId: 16,
              store: {
                id: 2,
                address: 'г Санкт-Петербург, г Колпино',
                lat: '59.746632',
                lng: '30.595469',
                title: 'Тестовый Магазин 2',
                phone: '+76464646546',
                ownerId: 4,
              },
              amount: 100,
            },
          ],
        },
        {
          id: 256,
          product: {
            id: 256,
            code: '1670754329213',
            title: 'Алтайская говядина к/в мгс вес упак',
            description: '',
            price: 0,
            photos: '',
            createdAt: '2022-12-11T10:25:29.213Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 33,
          orderItems: [
            {
              id: 70,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 33,
            },
          ],
        },
        {
          id: 508,
          product: {
            id: 508,
            code: '1670754330649',
            title: 'ЭЛИТНАЯ колбаса с/к (0,2гр шт.) охл (ДОБРОГОСТ) БЕЗ ВОЗВРАТОВ',
            description: '',
            price: 32900,
            photos: '',
            createdAt: '2022-12-11T10:25:30.649Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 144,
          orderItems: [
            {
              id: 60,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 100,
            },
            {
              id: 73,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 10,
            },
            {
              id: 88,
              orderId: 17,
              store: {
                id: 1,
                address: 'г Санкт-Петербург, г Пушкин',
                lat: '59.72034',
                lng: '30.404469',
                title: 'Тестовый Магазин',
                phone: '+78005553535',
                ownerId: 2,
              },
              amount: 1,
            },
            {
              id: 83,
              orderId: 16,
              store: {
                id: 2,
                address: 'г Санкт-Петербург, г Колпино',
                lat: '59.746632',
                lng: '30.595469',
                title: 'Тестовый Магазин 2',
                phone: '+76464646546',
                ownerId: 4,
              },
              amount: 33,
            },
          ],
        },
        {
          id: 507,
          product: {
            id: 507,
            code: '1670754330644',
            title: 'ЧОРИЗО с/к п/сух (0,3 кг шт) вес упак (Ч)',
            description: '',
            price: 27900,
            photos: '',
            createdAt: '2022-12-11T10:25:30.644Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 312,
          orderItems: [
            {
              id: 61,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 200,
            },
            {
              id: 74,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 110,
            },
            {
              id: 89,
              orderId: 17,
              store: {
                id: 1,
                address: 'г Санкт-Петербург, г Пушкин',
                lat: '59.72034',
                lng: '30.404469',
                title: 'Тестовый Магазин',
                phone: '+78005553535',
                ownerId: 2,
              },
              amount: 2,
            },
          ],
        },
        {
          id: 478,
          product: {
            id: 478,
            code: '1670754330508',
            title: 'Сыр Российский особый 45% ТМ САРМИЧ весовой',
            description: '',
            price: 0,
            photos: '',
            createdAt: '2022-12-11T10:25:30.508Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 10,
          orderItems: [
            {
              id: 77,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 10,
            },
          ],
        },
        {
          id: 477,
          product: {
            id: 477,
            code: '1670754330500',
            title: 'Сыр Российский м.д.ж 50% ГОСТ 32260-2013 полубрус',
            description: '',
            price: 19900,
            photos: '',
            createdAt: '2022-12-11T10:25:30.500Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 110,
          orderItems: [
            {
              id: 78,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 110,
            },
          ],
        },
        {
          id: 476,
          product: {
            id: 476,
            code: '1670754330492',
            title: 'Сыр Российский м.д.ж 50% ГОСТ 32260-2013 круг (7-8кг)',
            description: '',
            price: 0,
            photos: '',
            createdAt: '2022-12-11T10:25:30.492Z',
            supplierId: 6,
            supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
          },
          amount: 100,
          orderItems: [
            {
              id: 79,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 100,
            },
          ],
        },
      ],
    },
    {
      id: 7,
      supplier: {
        id: 3,
        name: 'ООО "РОГА И КОПЫТА"',
        entityCode: '7725396754',
        contact: 'Иван Иванович',
        phone: '+79451111111',
      },
      sum: 1009800,
      amount: 3,
      status: 'created',
      supplierId: 3,
      createdAt: '2022-12-28T18:51:30.950Z',
      num: 2,
      supplierOrderItems: [
        {
          id: 254,
          product: {
            id: 254,
            code: '1670323027954',
            title: 'Товар',
            description: 'Описание товара',
            price: 10200,
            photos: '',
            createdAt: '2022-12-06T10:37:08.017Z',
            supplierId: 3,
            supplier: {
              id: 3,
              name: 'ООО "РОГА И КОПЫТА"',
              entityCode: '7725396754',
              contact: 'Иван Иванович',
              phone: '+79451111111',
            },
          },
          amount: 300,
          orderItems: [
            {
              id: 72,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 99,
            },
            {
              id: 82,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 100,
            },
            {
              id: 87,
              orderId: 16,
              store: {
                id: 2,
                address: 'г Санкт-Петербург, г Колпино',
                lat: '59.746632',
                lng: '30.595469',
                title: 'Тестовый Магазин 2',
                phone: '+76464646546',
                ownerId: 4,
              },
              amount: 101,
            },
          ],
        },
      ],
    },
    {
      id: 6,
      supplier: { id: 5, name: 'ООО "РИКО"', entityCode: '7730650950', contact: null, phone: null },
      sum: 330000,
      amount: 3,
      status: 'created',
      supplierId: 5,
      createdAt: '2022-12-28T18:51:30.938Z',
      num: 1,
      supplierOrderItems: [
        {
          id: 255,
          product: {
            id: 255,
            code: '1670748452239',
            title: 'Кока-кола',
            description: '',
            price: 10000,
            photos: '',
            createdAt: '2022-12-11T08:47:32.254Z',
            supplierId: 5,
            supplier: { id: 5, name: 'ООО "РИКО"', entityCode: '7730650950', contact: null, phone: null },
          },
          amount: 102,
          orderItems: [
            {
              id: 71,
              orderId: 14,
              store: {
                id: 5,
                address: 'г Волгоград, Юбилейный пер, влд 6',
                lat: '48.707103',
                lng: '44.516939',
                title: 'Юбилейный',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 33,
            },
            {
              id: 81,
              orderId: 15,
              store: {
                id: 3,
                address: 'г Волгоград, ул им. композитора Танеева, д 2',
                lat: '48.5233905',
                lng: '44.6044427',
                title: 'Танеева',
                phone: '+79999999999',
                ownerId: 5,
              },
              amount: 34,
            },
            {
              id: 91,
              orderId: 17,
              store: {
                id: 1,
                address: 'г Санкт-Петербург, г Пушкин',
                lat: '59.72034',
                lng: '30.404469',
                title: 'Тестовый Магазин',
                phone: '+78005553535',
                ownerId: 2,
              },
              amount: 35,
            },
          ],
        },
      ],
    },
  ],
  total: 3,
  next: false,
};
export const previewResponse = [
  {
    id: 5,
    supplier: { id: 5, name: 'ООО "РИКО"', entityCode: '7730650950', contact: null, phone: null },
    supplierId: 5,
    amount: 16,
    sum: 160000,
    status: 'created',
    createdAt: '2022-12-27T14:32:45.283Z',
    supplierOrderItems: [
      {
        id: 255,
        product: {
          id: 255,
          code: '1670748452239',
          title: 'Кока-кола',
          description: '',
          price: 10000,
          photos: '',
          createdAt: '2022-12-11T08:47:32.254Z',
          supplierId: 5,
          supplier: { id: 5, name: 'ООО "РИКО"', entityCode: '7730650950', contact: null, phone: null },
        },
        amount: 16,
        orderItems: [
          {
            id: 48,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 10,
          },
          {
            id: 52,
            orderId: 11,
            store: {
              id: 5,
              address: 'г Волгоград, Юбилейный пер, влд 6',
              lat: '48.707103',
              lng: '44.516939',
              title: 'Юбилейный',
              phone: '+79999999999',
              ownerId: 5,
            },
            amount: 6,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    supplier: {
      id: 3,
      name: 'ООО "РОГА И КОПЫТА"',
      entityCode: '7725396754',
      contact: 'Иван Иванович',
      phone: '+79451111111',
    },
    supplierId: 3,
    amount: 20,
    sum: 18400,
    status: 'created',
    createdAt: '2022-12-27T14:32:45.283Z',
    supplierOrderItems: [
      {
        id: 254,
        product: {
          id: 254,
          code: '1670323027954',
          title: 'Товар',
          description: 'Описание товара',
          price: 920,
          photos: '',
          createdAt: '2022-12-06T10:37:08.017Z',
          supplierId: 3,
          supplier: {
            id: 3,
            name: 'ООО "РОГА И КОПЫТА"',
            entityCode: '7725396754',
            contact: 'Иван Иванович',
            phone: '+79451111111',
          },
        },
        amount: 20,
        orderItems: [
          {
            id: 49,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 7,
          },
          {
            id: 53,
            orderId: 11,
            store: {
              id: 5,
              address: 'г Волгоград, Юбилейный пер, влд 6',
              lat: '48.707103',
              lng: '44.516939',
              title: 'Юбилейный',
              phone: '+79999999999',
              ownerId: 5,
            },
            amount: 13,
          },
        ],
      },
    ],
  },
  {
    id: 6,
    supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
    supplierId: 6,
    amount: 93,
    sum: 0,
    status: 'created',
    createdAt: '2022-12-27T14:32:45.283Z',
    supplierOrderItems: [
      {
        id: 508,
        product: {
          id: 508,
          code: '1670754330649',
          title: 'ЭЛИТНАЯ колбаса с/к (0,2гр шт.) охл (ДОБРОГОСТ) БЕЗ ВОЗВРАТОВ',
          description: '',
          price: 0,
          photos: '',
          createdAt: '2022-12-11T10:25:30.649Z',
          supplierId: 6,
          supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
        },
        amount: 31,
        orderItems: [
          {
            id: 42,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 11,
          },
          {
            id: 50,
            orderId: 11,
            store: {
              id: 5,
              address: 'г Волгоград, Юбилейный пер, влд 6',
              lat: '48.707103',
              lng: '44.516939',
              title: 'Юбилейный',
              phone: '+79999999999',
              ownerId: 5,
            },
            amount: 20,
          },
        ],
      },
      {
        id: 507,
        product: {
          id: 507,
          code: '1670754330644',
          title: 'ЧОРИЗО с/к п/сух (0,3 кг шт) вес упак (Ч)',
          description: '',
          price: 0,
          photos: '',
          createdAt: '2022-12-11T10:25:30.644Z',
          supplierId: 6,
          supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
        },
        amount: 23,
        orderItems: [
          {
            id: 43,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 22,
          },
          {
            id: 51,
            orderId: 11,
            store: {
              id: 5,
              address: 'г Волгоград, Юбилейный пер, влд 6',
              lat: '48.707103',
              lng: '44.516939',
              title: 'Юбилейный',
              phone: '+79999999999',
              ownerId: 5,
            },
            amount: 1,
          },
        ],
      },
      {
        id: 506,
        product: {
          id: 506,
          code: '1670754330640',
          title: 'Сергеевская с/к порц 0,17кг вес упак (Ч)',
          description: '',
          price: 0,
          photos: '',
          createdAt: '2022-12-11T10:25:30.640Z',
          supplierId: 6,
          supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
        },
        amount: 33,
        orderItems: [
          {
            id: 44,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 33,
          },
        ],
      },
      {
        id: 258,
        product: {
          id: 258,
          code: '1670754329228',
          title: 'Без свинины сервелат в/к (срез 350г) в/у вес упак',
          description: '',
          price: 0,
          photos: '',
          createdAt: '2022-12-11T10:25:29.228Z',
          supplierId: 6,
          supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
        },
        amount: 1,
        orderItems: [
          {
            id: 45,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 1,
          },
        ],
      },
      {
        id: 257,
        product: {
          id: 257,
          code: '1670754329222',
          title: 'Астраханская колбаса (500гр.шт.) п/к мгс вес упак.',
          description: '',
          price: 0,
          photos: '',
          createdAt: '2022-12-11T10:25:29.222Z',
          supplierId: 6,
          supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
        },
        amount: 2,
        orderItems: [
          {
            id: 46,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 2,
          },
        ],
      },
      {
        id: 256,
        product: {
          id: 256,
          code: '1670754329213',
          title: 'Алтайская говядина к/в мгс вес упак',
          description: '',
          price: 0,
          photos: '',
          createdAt: '2022-12-11T10:25:29.213Z',
          supplierId: 6,
          supplier: { id: 6, name: 'Волжский', entityCode: null, contact: null, phone: null },
        },
        amount: 3,
        orderItems: [
          {
            id: 47,
            orderId: 10,
            store: {
              id: 1,
              address: 'г Санкт-Петербург, г Пушкин',
              lat: '59.72034',
              lng: '30.404469',
              title: 'Тестовый Магазин',
              phone: '+78005553535',
              ownerId: 2,
            },
            amount: 3,
          },
        ],
      },
    ],
  },
];
