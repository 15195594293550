export const urls = {
  signIn: '/api/v1/auth/login',
  signInGoogle: '/api/v1/auth/google-login',
  getProfile: '/api/v1/profile',

  getRoles: '/api/v1/rbac',
  createRole: '/api/v1/rbac',
  updateRole: '/api/v1/rbac/:id',
  removeRole: '/api/v1/rbac/:id',

  getOperators: '/api/v1/operators',
  getOperator: '/api/v1/operators/:id',
  createOperator: '/api/v1/operators',
  updateOperator: '/api/v1/operators/:id',
  removeOperator: '/api/v1/operators/:id',

  getLogs: '/api/v1/system-logs',

  removeOrders: '/api/v1/admin/remove-orders',
};
