import { CancelTokenSource } from 'axios';

import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { GetProductCategoriesParams, ProductCategoryDto } from './types';

function getProductCategories(params: GetProductCategoriesParams, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    cancelToken: cancel.token,
    method: 'GET',
    url: urls.getProductCategories,
    params,
  });
}

function createProductCategory(data: ProductCategoryDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createProductCategory,
    data,
  });
}

export default {
  getProductCategories,
  createProductCategory,
};
