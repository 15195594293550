import { types, Instance } from 'mobx-state-tree';

export const Shop = types.model({
  id: types.maybeNull(types.number),
  key: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  lat: types.maybeNull(types.string),
  lng: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  ownerId: types.maybeNull(types.number),
  telegramUser: types.maybeNull(types.string),
});

export interface IShop extends Instance<typeof Shop> {}

export const Stores = types.array(Shop);

export interface IStores extends Instance<typeof Stores> {}

export const GetStores = types.model({
  list: types.maybeNull(Stores),
  next: types.maybeNull(types.boolean),
  total: types.maybeNull(types.number),
});

export interface IGetStores extends Instance<typeof GetStores> {}
