import { types, Instance } from 'mobx-state-tree';
import { Product } from '../products/models';
import { Shop } from '../stores/models';

export const OrderItemsItem = types.model({
  id: types.maybeNull(types.number),
  productId: types.maybeNull(types.number),
  orderId: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  comment: types.maybeNull(types.string),
  product: types.maybeNull(Product),
});
export interface IOrderItemsItem extends Instance<typeof OrderItemsItem> {}

export const OrderItems = types.array(OrderItemsItem);

export interface IOrderItems extends Instance<typeof OrderItems> {}

export const Order = types.model({
  id: types.maybeNull(types.number),
  num: types.maybeNull(types.number),
  storeId: types.maybeNull(types.number),
  store: types.maybeNull(Shop),
  actorId: types.maybeNull(types.number),
  sum: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  orderItems: types.maybeNull(OrderItems),
});

export interface IOrder extends Instance<typeof Order> {}

export const OrderHistoryItem = types.model({
  id: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});

export interface IOrderHistoryItem extends Instance<typeof OrderHistoryItem> {}

export const OrderHistory = types.array(OrderHistoryItem);

export interface IOrderHistory extends Instance<typeof OrderHistory> {}

export const CheckOrder = types.model({
  orderId: types.maybeNull(types.number),
  closed: types.maybeNull(types.boolean),
});
