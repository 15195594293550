import React from 'react';
import { types, Instance, flow, getSnapshot } from 'mobx-state-tree';

import { mediaBreakpoints } from 'components/constants';
import formatLabel from 'utils/formatLabel';
import alerts from 'general/alerts';
import apiDp, { getRefreshTokenExpTime, setTokens, removeTokens, Operator, ISignInResponse, IOperator } from 'api/dp';
import { getRoutesWithPermissions, getNavigationWithPermissions } from 'application/utils';

import { Containers } from './types';
import { captions } from './constants';

const Data = types.model({
  profile: types.maybeNull(Operator),
});

export const Store = types
  .model({
    mounted: types.boolean,
    loading: types.boolean,
    redirect: types.maybeNull(
      types.model({
        to: types.string,
        replace: types.maybeNull(types.boolean),
      }),
    ),
    authorized: types.boolean,
    mediaSize: types.maybeNull(types.string),
    data: Data,
  })

  .volatile<{ containers: Containers }>(() => ({
    containers: {},
  }))

  .views((self) => ({
    get captions() {
      return captions;
    },

    get isMobile() {
      if (!self.mediaSize) return null;
      return self.mediaSize === 'mobile';
    },

    get isTablet() {
      if (!self.mediaSize) return null;
      return self.mediaSize === 'tablet';
    },

    get isDesktop() {
      if (!self.mediaSize) return null;
      return self.mediaSize === 'desktop';
    },

    get profile() {
      if (!self.data.profile) return {} as IOperator;
      return getSnapshot(self.data.profile) as IOperator;
    },

    get routes() {
      return getRoutesWithPermissions(this.profile);
    },

    get navigation() {
      return getNavigationWithPermissions(this.profile);
    },

    get user() {
      if (!self.authorized) return null;

      return {
        avatar: this.profile.avatarSrc,
        name: this.profile.fullName,
        signOut: this.captions.signOut,
      };
    },

    get version() {
      return formatLabel(this.captions.version, { value: process.env.REACT_APP_VERSION });
    },
  }))

  .actions((self) => {
    const setError = (error: any) => {
      if (error.message === 'Do not receive new token') {
        onSignOut();
        return;
      }
      if (error?.response?.status === 403) return;
      alerts.add({ message: error.message, type: 'error' });
      console.log(error); /* eslint-disable-line */
    };

    const getData = flow(function* async() {
      try {
        self.loading = true;
        const refreshTokenExpTime = getRefreshTokenExpTime();
        if (refreshTokenExpTime <= 0) {
          self.data.profile = null;
          self.authorized = false;
        } else {
          self.data.profile = yield apiDp.admin.getProfile();
          self.authorized = true;
        }
      } catch (e) {
        setError(e);
      } finally {
        self.loading = false;
      }
    });

    const onSignIn = (data: ISignInResponse) => {
      setTokens({ accessToken: data.accessToken, refreshToken: data.refreshToken });
      getData();
    };

    const onSignOut = () => {
      removeTokens();
      self.authorized = false;
      self.data.profile = null;
    };

    return {
      setError,
      getData,
      onSignIn,
      onSignOut,
    };
  })

  .actions((self) => ({
    mount: () => {
      if (self.mounted) return;
      self.getData();
      self.mounted = true;
    },

    unmount: () => {
      self.mounted = false;
      self.loading = false;
      self.redirect = null;
      self.authorized = false;
      self.data.profile = null;
    },

    setRedirect: (to: string, replace = false) => {
      self.redirect = { to, replace };
    },

    clearRedirect: () => {
      self.redirect = null;
    },

    setMediaSize: (windowWidth: number) => {
      if (windowWidth === undefined) self.mediaSize = null;
      else if (windowWidth >= mediaBreakpoints.desktop) self.mediaSize = 'desktop';
      else if (windowWidth >= mediaBreakpoints.tablet) self.mediaSize = 'tablet';
      else self.mediaSize = 'mobile';
    },
  }));

export const store = Store.create({
  mounted: false,
  loading: false,
  redirect: null,
  authorized: false,
  mediaSize: null,
  data: {},
});

export const StoreContext = React.createContext(store);

export const useStore = () => React.useContext(StoreContext);

export interface IStore extends Instance<typeof Store> {}

export function injectStore(containerId: string, containerStore: any) {
  store.containers[containerId] = containerStore;
  containerStore.root.store = store;
}
