import { IOperator } from 'api/dp';

import { routes } from 'application/routing';
import { navigation } from 'application/constants';
import { permissions } from 'application/globals';

import { LayoutNavigationItem } from 'components/molecules/Layout/types';

function checkPermissions(id: string, profileAdmin?: boolean, profilePermissions?: string[]) {
  if (profileAdmin) return true;
  if (!permissions[id]) return true;
  if (profilePermissions.find((permission) => permissions[id].includes(permission))) return true;
  return false;
}

export function getRoutesWithPermissions(profile: IOperator) {
  const profileAdmin = profile.isSuperAdmin || profile.isAdmin || false;
  const profilePermissions = profile.role?.permissions.map((item) => item.section) || [];
  return routes.filter((item) => checkPermissions(item.id, profileAdmin, profilePermissions));
}

function getNavigationItemsWithPermissions(
  items: LayoutNavigationItem[],
  profileAdmin?: boolean,
  profilePermissions?: string[],
): LayoutNavigationItem[] {
  return items
    .filter((item) => checkPermissions(item.id, profileAdmin, profilePermissions))
    .map((item) => ({
      ...item,
      items: item.items ? getNavigationItemsWithPermissions(item.items, profileAdmin, profilePermissions) : null,
    }));
}

export function getNavigationWithPermissions(profile: IOperator) {
  const profileAdmin = profile.isSuperAdmin || profile.isAdmin || false;
  const profilePermissions = profile.role?.permissions.map((item) => item.section) || [];
  return {
    home: navigation.home,
    items: getNavigationItemsWithPermissions(navigation.items, profileAdmin, profilePermissions),
  };
}
