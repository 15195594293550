import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { CheckOrderDto, GetOrdersParams, GetProductOrdersHistoryParams, OrderDto, UpdateOrderItemsDto } from './types';

function getOrders(params: GetOrdersParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getOrders,
    params,
  });
}

function getOrder(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getOrder.replace(':id', id),
  });
}

function createOrder(data: OrderDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createOrder,
    data,
  });
}

function updateOrder(id: string, data: OrderDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateOrder.replace(':id', id),
    data,
  });
}

function removeOrder(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeOrder.replace(':id', id),
  });
}

function updateOrderItems(data: UpdateOrderItemsDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PUT',
    url: urls.updateOrderItems,
    data,
  });
}

function getProductOrdersHistory(productId: string, params: GetProductOrdersHistoryParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getProductOrdersHistory.replace(':productId', productId),
    params,
  });
}

function checkOrder(data: CheckOrderDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.checkOrder,
    data,
  });
}

export default {
  getOrders,
  getOrder,
  createOrder,
  updateOrder,
  removeOrder,
  updateOrderItems,
  getProductOrdersHistory,
  checkOrder,
};
