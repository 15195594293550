import React from 'react';

import { IconProps } from './types';
import IconPrimitive from './_internal/IconPrimitive';

const IconArrowUpTailed: React.FC<IconProps> = (props) => {
  return (
    <IconPrimitive {...props} viewBox={20}>
      <path d="M9.25,4.31066017 L3.53033009,10.0303301 C3.23743687,10.3232233 2.76256313,10.3232233 2.46966991,10.0303301 C2.1767767,9.73743687 2.1767767,9.26256313 2.46966991,8.96966991 L9.46966991,1.96966991 C9.76256313,1.6767767 10.2374369,1.6767767 10.5303301,1.96966991 L17.5303301,8.96966991 C17.8232233,9.26256313 17.8232233,9.73743687 17.5303301,10.0303301 C17.2374369,10.3232233 16.7625631,10.3232233 16.4696699,10.0303301 L10.75,4.31066017 L10.75,17.5 C10.75,17.9142136 10.4142136,18.25 10,18.25 C9.58578644,18.25 9.25,17.9142136 9.25,17.5 L9.25,4.31066017 Z" />
    </IconPrimitive>
  );
};

export default IconArrowUpTailed;
