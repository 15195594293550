import { types, Instance } from 'mobx-state-tree';
import { Product } from '../products/models';
import { Supplier } from '../suppliers/models';
import { Shop } from '../stores/models';

export const SupplierOrderItemOrderItem = types.model({
  id: types.maybeNull(types.number),
  orderId: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  comment: types.maybeNull(types.string),
  store: types.maybeNull(Shop),
});

export interface ISupplierOrderItemOrderItem extends Instance<typeof SupplierOrderItemOrderItem> {}
export const SupplierOrderItem = types.model({
  id: types.maybeNull(types.number),
  orderItems: types.maybeNull(types.array(SupplierOrderItemOrderItem)),
  product: types.maybeNull(Product),
  amount: types.maybeNull(types.number),
});

export interface ISupplierOrderItem extends Instance<typeof SupplierOrderItem> {}

export const SupplierOrderItems = types.array(SupplierOrderItem);

export interface ISupplierOrderItems extends Instance<typeof SupplierOrderItems> {}

export const SupplierOrder = types.model({
  id: types.maybeNull(types.number),
  num: types.maybeNull(types.number),
  supplierId: types.maybeNull(types.number),
  sum: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  supplier: types.maybeNull(Supplier),
  supplierOrderItems: types.maybeNull(SupplierOrderItems),
});

export interface ISupplierOrder extends Instance<typeof SupplierOrder> {}

export const SupplierOrders = types.array(SupplierOrder);
export interface ISupplierOrders extends Instance<typeof SupplierOrders> {}

export const SupplierOrdersGroupStore = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  orderId: types.maybeNull(types.number),
});

export interface ISupplierOrdersGroupStore extends Instance<typeof SupplierOrdersGroupStore> {}

export const SupplierOrdersGroupStores = types.array(SupplierOrdersGroupStore);

export const SupplierOrdersGroup = types.model({
  id: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  stores: types.maybeNull(SupplierOrdersGroupStores),
});

export interface ISupplierOrdersGroup extends Instance<typeof SupplierOrdersGroup> {}
