export const roleSections = {
  admin: 'admin',
  log: 'log',
  store: 'store',
  product: 'product',
  order: 'order',
  supplier: 'supplier',
  supplierOrder: 'supplierOrder',
  contentCreate: 'contentCreate',
  contentView: 'contentView',
};

export const roleSectionsCaptions = {
  [roleSections.admin]: 'admin',
  [roleSections.log]: 'log',
  [roleSections.store]: 'store',
  [roleSections.product]: 'product',
  [roleSections.order]: 'order',
  [roleSections.supplier]: 'supplier',
  [roleSections.supplierOrder]: 'supplier order',
  [roleSections.contentCreate]: 'content create',
  [roleSections.contentView]: 'content view',
};

export const orderStatuses = {
  created: 'created',
  in_delivery: 'in_delivery',
  approved: 'approved',
  postponed: 'postponed',
  paid: 'paid',
  deliveredToFacility: 'delivered_to_facility',
  deliveredToStore: 'delivered_to_store',
};

export const orderStatusesCaptions = {
  [orderStatuses.created]: 'Создан',
  [orderStatuses.approved]: 'Подтвержден',
  [orderStatuses.paid]: 'Оплачен',
  [orderStatuses.deliveredToFacility]: 'Доставлено на склад',
  [orderStatuses.deliveredToStore]: 'Отправлено в магазин',
  [orderStatuses.in_delivery]: 'В доставке',
  [orderStatuses.postponed]: 'Отложен',
};

export const productsSortBy = {
  createdAt: 'createdAt',
  name: 'name',
  price: 'price',
  supplier: 'supplier',
  categoryDate: 'categoryDate',
  categoryTitle: 'categoryTitle',
  title: 'title',
};

export const sortOrders = {
  asc: 'asc',
  desc: 'desc',
};

export const additionalFields = {
  last3Orders: 'last3Orders',
};
