import { types, flow } from 'mobx-state-tree';

import requests from './requests';

export const Store = types.model().actions(() => ({
  getUploadUrl: flow(function* request(params) {
    const response = yield requests.getUploadUrl(params);
    return response?.data;
  }),

  getFileUrl: flow(function* request(params) {
    const response = yield requests.getFileUrl(params);
    return response?.data;
  }),

  uploadFile: flow(function* request(params) {
    const response = yield requests.uploadFile(params);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
