export const urls = {
  getStores: '/api/v1/stores',
  getStore: '/api/v1/stores/:id',
  createStore: '/api/v1/stores',
  updateStore: '/api/v1/stores/:id',
  removeStore: '/api/v1/stores/:id',
  getWarehouse: '/api/v1/stores/storage',
  createStoreProducts: '/api/v1/stores/products',
  getStoreProducts: '/api/v1/stores/:id/products',
};
