import globals from 'application/globals';

export const captions = {
  signOut: globals.captions.actions.signOut,
  version: 'v.{:value}',
};

export const navigation = {
  home: globals.pages.home,
  items: [
    {
      ...globals.pages.administration.main,
      items: [
        globals.pages.administration.roles.main,
        globals.pages.administration.operators.main,
        globals.pages.administration.logs.main,
      ],
    },
    {
      ...globals.pages.business.main,
      items: [
        globals.pages.business.stores.main,
        globals.pages.business.suppliers.main,
        globals.pages.business.products.main,
        globals.pages.business.warehouse.main,
      ],
    },
    {
      ...globals.pages.orders.main,
      items: [globals.pages.orders.storeOrders.main, globals.pages.orders.orderManagement.main],
    },
    {
      ...globals.pages.content.main,
      items: [globals.pages.content.articles.main, globals.pages.content.articlesManagement.main],
    },
  ],
};
