import { CancelTokenSource } from 'axios';
import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { StoreDto, GetStoresParams, SaveStoreProductsDto, GetStoreProductsParams } from './types';

function getStores(params: GetStoresParams, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    cancelToken: cancel.token,
    method: 'GET',
    url: urls.getStores,
    params,
  });
}

function getStore(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getStore.replace(':id', id),
  });
}

function createStore(data: StoreDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createStore,
    data,
  });
}

function updateStore(id: string, data: StoreDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateStore.replace(':id', id),
    data,
  });
}

function removeStore(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeStore.replace(':id', id),
  });
}

function getWarehouse() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getWarehouse,
  });
}

function saveStoreProducts(data: SaveStoreProductsDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createStoreProducts,
    data,
  });
}

function getStoreProducts(id: string, params: GetStoreProductsParams, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    cancelToken: cancel.token,
    method: 'GET',
    url: urls.getStoreProducts.replace(':id', id),
    params,
  });
}

export default {
  getStores,
  getStore,
  createStore,
  updateStore,
  removeStore,
  getWarehouse,
  saveStoreProducts,
  getStoreProducts,
};
