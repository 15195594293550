import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';

import useWindowDimension from 'utils/useWindowDimension';

import Alerts from 'components/atoms/Alerts';
import Layout from 'components/molecules/Layout';
import PageLoader from 'components/molecules/PageLoader';
import ScrollToTop from 'components/molecules/ScrollToTop';

import SignIn from 'modules/SignIn';

import { ApplicationProps } from './types';

const Application: React.FC<ApplicationProps> = (props) => {
  const { store } = props;
  const {
    mounted,
    mount,
    unmount,
    loading,
    routes,
    redirect,
    authorized,
    clearRedirect,
    navigation,
    user,
    version,
    setMediaSize,
    onSignOut,
  } = store;

  const location = useLocation();
  const { width: windowWidth } = useWindowDimension();

  useEffect(() => {
    if (!mounted) mount();

    return () => {
      if (mounted) unmount();
    };
  }, [mounted]);

  useEffect(() => {
    if (redirect) clearRedirect();
  }, [redirect]);

  useEffect(() => {
    setMediaSize(windowWidth);
  }, [windowWidth]);

  if (!mounted) return null;

  return (
    <>
      {redirect && <Navigate {...redirect} />}
      <Layout location={location} navigation={navigation} user={user} version={version} onSignOut={onSignOut}>
        {loading && <PageLoader />}
        {!loading && !authorized && <SignIn />}
        {!loading && authorized && (
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <React.Suspense fallback={null}>
                    <route.component />
                  </React.Suspense>
                }
              />
            ))}
          </Routes>
        )}
      </Layout>
      <Alerts />
      <ScrollToTop />
    </>
  );
};

export default observer(Application);
