import { constraintsTypes } from '../../utils/checkConstraints';

export const fields = {
  email: 'email',
  password: 'password',
};

export const signInRedirectUrl = '/api/v1/auth/google-login-request';

export const captions = {
  title: 'Авторизация',
  authorizing: 'Подождите, проверяем информацию...',
  success: 'Добро пожаловать! Вы успешно авторизовались',
  fail: 'Доступ запрещен! Вы не имеете доступа к данному приложению',
  fields: {
    [fields.email]: 'Почта',
    [fields.password]: 'Пароль',
  },
  buttons: {
    signIn: 'Войти',
    signInGoogle: 'Войти через Google',
  },
};

export const constraints = {
  [fields.email]: [
    {
      type: constraintsTypes.required,
      message: 'Укажите электронную почту',
    },
    {
      type: constraintsTypes.pattern,
      message: 'Неверный формат электронной почты',
      condition: '[_A-Za-z0-9-\\.]+\\@[A-Za-z0-9-]{2,}\\.[A-Za-z]{2,}',
    },
  ],
  [fields.password]: [
    {
      type: constraintsTypes.required,
      message: 'Укажите пароль',
    },
  ],
};
