import styled from 'styled-components';
import { StyledIconSvg } from 'components/icons/style';
import theme from './theme';

export const StyledScrollToTop = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  bottom: ${theme.bottom}px;
  right: ${theme.right}px;
  width: ${theme.size}px;
  height: ${theme.size}px;
  background: ${theme.backgroundColors.normal};
  border-radius: ${theme.borderRadius}px;
  transition: all 0.2s ease-out;
  cursor: pointer;

  ${StyledIconSvg} {
    fill: ${theme.colors.normal};
  }

  :hover {
    background: ${theme.backgroundColors.hover};

    ${StyledIconSvg} {
      fill: ${theme.colors.hover};
    }
  }
`;
