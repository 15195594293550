import React from 'react';
import ReactDOM from 'react-dom/client';

import Application from 'application';
import { configureMockAdapter } from 'api/mock';

function init() {
  if (process.env.REACT_APP_MOCK === 'true') configureMockAdapter();

  const root = ReactDOM.createRoot(document.querySelector('#root'));

  root.render(
    <React.StrictMode>
      <Application />
    </React.StrictMode>,
  );
}

document.addEventListener('DOMContentLoaded', init);
