import { AxiosRequestConfig } from 'axios';

import { logRequest } from 'api/utils';

import { urls } from '../constants';

import { articleResponse, articlesResponse } from './data';

const jsonHeaders = { 'content-type': 'application/json' };

const productsList = (config: AxiosRequestConfig) => logRequest(config, [200, articlesResponse, jsonHeaders]);
const productItem = (config: AxiosRequestConfig) => logRequest(config, [200, articleResponse, jsonHeaders]);

export default function mockAdapter(axiosMockInstance: any) {
  axiosMockInstance.onPost(urls.createArticle).reply(productItem);
  axiosMockInstance.onGet(urls.getArticles).reply(productsList);
}
