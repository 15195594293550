import { CancelTokenSource } from 'axios';

import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { GetBalanceParams, GetProductsParams, ProductDto, RemoveProductsParams, UpdateProductsDto } from './types';

function getProducts(params: GetProductsParams, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    cancelToken: cancel.token,
    method: 'GET',
    url: urls.getProducts,
    params,
  });
}

function getProduct(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getProduct.replace(':id', id),
  });
}

function createProduct(data: ProductDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createProduct,
    data,
  });
}

function updateProduct(id: string, data: ProductDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateProduct.replace(':id', id),
    data,
  });
}

function removeProduct(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeProduct.replace(':id', id),
  });
}

function removeProducts(params: RemoveProductsParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeProducts,
    params,
  });
}

function updateProducts(data: UpdateProductsDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PUT',
    url: urls.updateProducts,
    data,
  });
}

function getBalance(storeId: string, params: GetBalanceParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getBalance.replace(':storeId', storeId),
    params,
  });
}

export default {
  getProducts,
  getProduct,
  createProduct,
  updateProduct,
  removeProduct,
  removeProducts,
  updateProducts,
  getBalance,
};
