import React, { useEffect, useState } from 'react';

import IconArrowUpTailed from 'components/icons/IconArrowUpTailed';

import { StyledScrollToTop } from './style';

const ScrollToTop: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) setVisible(true);
    else setVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    visible && (
      <StyledScrollToTop onClick={scrollToTop}>
        <IconArrowUpTailed />
      </StyledScrollToTop>
    )
  );
};

export default ScrollToTop;
