import { AxiosRequestConfig } from 'axios';

import { logRequest } from 'api/utils';

import { urls } from '../constants';

import { signInResponse, operator } from './data';

const jsonHeaders = { 'content-type': 'application/json' };

const signIn = (config: AxiosRequestConfig) => logRequest(config, [200, signInResponse, jsonHeaders]);

const getProfile = (config: AxiosRequestConfig) => logRequest(config, [200, operator, jsonHeaders]);

export default function mockAdapter(axiosMockInstance: any) {
  axiosMockInstance.onPost(urls.signIn).reply(signIn);
  axiosMockInstance.onGet(urls.getProfile).reply(getProfile);
}
