export const storageKeys = {
  accessToken: 'DIGITAL_POINT_ADMIN_ACCESS_TOKEN',
  refreshToken: 'DIGITAL_POINT_ADMIN_REFRESH_TOKEN',
  view: 'DIGITAL_POINT_ADMIN_VIEW',
  draft: 'DIGITAL_POINT_ADMIN_DRAFT',
};

export const urls = {
  refreshToken: '/api/v1/auth/refresh',
};

export const environments = {
  development: 'development',
  production: 'production',
};
