import { IOperatorRolePermission } from '../models';

export const signInResponse = {
  accessToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwidXNlcm5hbWUiOiJkZWNpYXNzZXR0ZXNpbW9AZ21haWwuY29tIiwidHlwZSI6ImFkbWluIiwiaWF0IjoxNjY5Mjc0NTI1LCJleHAiOjE2NjkyNzU0MjV9.Rt9e2PWkzp9gh0Cdo5fhS1ly_teAL2AoUnwBbfpUuPE',
  refreshToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NjAsInVzZXJuYW1lIjoiZGVjaWFzc2V0dGVzaW1vQGdtYWlsLmNvbSIsInR5cGUiOiJhZG1pbiIsImlhdCI6MTY2OTI3NDUyNSwiZXhwIjoxNjc0NDU4NTI1fQ.FsQS3oA6QgebbY_ZEhGDklWp6kkh7oDWro23vH1XCt0',
  user: { id: 1, username: 'mail@gmail.com' },
};

export const operator = {
  id: 1,
  email: 'mail@gmail.com',
  fullName: 'Test Admin',
  avatarSrc: null as string,
  isAdmin: true,
  isSuperAdmin: true,
  roleId: 1,
  role: { id: 1, title: 'Default', permissions: [] as IOperatorRolePermission[] },
  createdAt: '2022-09-01T14:25:19.909Z',
};
