import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { SignInDto, OperatorRoleDto, OperatorDto, GetLogsParams, SignInGoogleDto } from './types';

function signIn(data: SignInDto) {
  return getApiInstance()({
    method: 'post',
    url: urls.signIn,
    data,
  });
}

function signInGoogle(data: SignInGoogleDto) {
  return getApiInstance()({
    method: 'post',
    url: urls.signInGoogle,
    data,
  });
}

function getProfile() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'get',
    url: urls.getProfile,
  });
}

function getRoles() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getRoles,
  });
}

function createRole(data: OperatorRoleDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createRole,
    data,
  });
}

function updateRole(id: string, data: OperatorRoleDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateRole.replace(':id', id),
    data,
  });
}

function removeRole(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeRole.replace(':id', id),
  });
}

function getOperators() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getOperators,
  });
}

function getOperator(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getOperator.replace(':id', id),
  });
}

function createOperator(data: OperatorDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createOperator,
    data,
  });
}

function updateOperator(id: string, data: OperatorDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateOperator.replace(':id', id),
    data,
  });
}

function removeOperator(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeOperator.replace(':id', id),
  });
}

function getLogs(params: GetLogsParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getLogs,
    params,
  });
}

function removeOrders() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.removeOrders,
  });
}

export default {
  signIn,
  signInGoogle,
  getProfile,

  getRoles,
  createRole,
  updateRole,
  removeRole,

  getOperators,
  getOperator,
  createOperator,
  updateOperator,
  removeOperator,

  getLogs,
  removeOrders,
};
