import { AxiosRequestConfig } from 'axios';

const style1 = 'font-weight: normal; color: #888888;';
const style2 = 'font-weight: bold; color: #cccccc;';
const prefix = 'mock request';

export type ResponseType = Array<any>;

export const logRequest = (config: AxiosRequestConfig, response: ResponseType) => {
  if (process.env.REACT_APP_MOCK === 'true') {
    /* eslint-disable no-console */
    console.groupCollapsed('%c %s %c%s', style1, prefix, style2, `${config.method} ${config.url}`);
    console.log('---- config ----');
    console.log(config);
    console.log('----------------');
    console.log('----- data -----');
    console.log(typeof config.data === 'string' ? JSON.parse(config.data) : config.data);
    console.log('----------------');
    console.log('--- response ---');
    console.log(response);
    console.log('----------------');
    console.groupEnd();
  }
  return response;
};
