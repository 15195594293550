import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { CancelTokenSource } from 'axios';
import { getCancelToken } from '../../axios';

let cancelGetSuppliers: CancelTokenSource = null;
export const Store = types.model().actions(() => ({
  getSuppliers: flow(function* request(params) {
    cancelGetSuppliers = getCancelToken(cancelGetSuppliers);
    const response = yield requests.getSuppliers(params, cancelGetSuppliers);
    return response?.data;
  }),

  getSupplier: flow(function* request(id) {
    const response = yield requests.getSupplier(id);
    return response?.data;
  }),

  createSupplier: flow(function* request(data) {
    const response = yield requests.createSupplier(data);
    return response?.data;
  }),

  updateSupplier: flow(function* request(id, data) {
    const response = yield requests.updateSupplier(id, data);
    return response?.data;
  }),

  removeSupplier: flow(function* request(id) {
    const response = yield requests.removeSupplier(id);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
