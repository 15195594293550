import { Instance, types } from 'mobx-state-tree';

export const ProductCategory = types.model({
  id: types.maybeNull(types.number),
  supplierId: types.maybeNull(types.number),
  key: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});

export interface IProductCategory extends Instance<typeof ProductCategory> {}
