import React from 'react';

import { IconProps } from './types';
import IconPrimitive from './_internal/IconPrimitive';

const IconClose: React.FC<IconProps> = (props) => {
  return (
    <IconPrimitive {...props} viewBox={24}>
      <path d="M5.29289,6.70711 C4.90237,6.31658 4.90237,5.68342 5.29289,5.29289 C5.68342,4.90237 6.31658,4.90237 6.70711,5.29289 L12,10.5858 L17.2929,5.29289 C17.6834,4.90237 18.3166,4.90237 18.7071,5.29289 C19.0976,5.68342 19.0976,6.31658 18.7071,6.70711 L13.4142,12 L18.7071,17.2929 C19.0976,17.6834 19.0976,18.3166 18.7071,18.7071 C18.3166,19.0976 17.6834,19.0976 17.2929,18.7071 L12,13.4142 L6.70711,18.7071 C6.31658,19.0976 5.68342,19.0976 5.29289,18.7071 C4.90237,18.3166 4.90237,17.6834 5.29289,17.2929 L10.5858,12 L5.29289,6.70711 Z" />
    </IconPrimitive>
  );
};

export default IconClose;
