import { createInstance } from 'api/axios';

import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { GetFileUrlParams, GetUploadUrlParams, UploadFileParams } from './types';

function getUploadUrl(params: GetUploadUrlParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getUploadUrl,
    params,
  });
}

const getFileUrl = (params: GetFileUrlParams) =>
  getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getFileUrl,
    params,
  });

const uploadFile = ({ url, file, contentType, onUploadProgress }: UploadFileParams) =>
  createInstance(url)({
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
    },
    onUploadProgress,
    data: file,
  });

export default {
  getUploadUrl,
  getFileUrl,
  uploadFile,
};
