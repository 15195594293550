import { types, flow, Instance, getSnapshot } from 'mobx-state-tree';
import { Location } from 'history';
import queryString from 'query-string';

import checkConstraints from 'utils/checkConstraints';
import { InputEventParams } from 'components/inputs/types';

import alerts from 'general/alerts';
import apiDp from 'api/dp';

import { captions } from './constants';

import { signInRedirectUrl, fields, constraints } from './constants';

export const Touched = types.model(
  Object.values(fields).reduce((result, item) => ({ ...result, [item]: types.maybeNull(types.boolean) }), {}),
);

export const Values = types.model(
  Object.values(fields).reduce((result, item) => ({ ...result, [item]: types.maybeNull(types.string) }), {}),
);

export interface IValues extends Instance<typeof Values> {}

export const Data = types.model({
  values: Values,
  touched: Touched,
});

export const Store = types
  .model({
    mounted: types.boolean,
    authorizing: types.boolean,
    reload: types.boolean,
    sending: types.boolean,
    data: Data,
  })

  .volatile(() => ({
    root: {
      store: null,
    },
  }))

  .views((self) => ({
    get captions() {
      return captions;
    },

    get values() {
      return getSnapshot(self.data.values);
    },

    get touched() {
      return getSnapshot(self.data.touched);
    },

    get constraints() {
      return Object.keys(constraints).reduce((result, item) => ({ ...result, [item]: constraints[item] }), {});
    },

    get errors() {
      return Object.values(fields).reduce((result, item) => {
        if (!this.disabledFields[item] && this.touched[item]) {
          return {
            ...result,
            [item]: checkConstraints(this.values[item], this.constraints[item], this.values),
          };
        }
        return result;
      }, {});
    },

    get hasErrors() {
      return !!Object.values(fields).filter(
        (item) =>
          !this.disabledFields[item] && checkConstraints(this.values[item], this.constraints[item], this.values),
      ).length;
    },

    get signInRedirectUrl() {
      return `${process.env.REACT_APP_DP_API_HOST}${signInRedirectUrl}`;
    },

    get disabledFields() {
      return Object.values(fields).reduce((result, item) => ({ ...result, [item]: self.sending }), {});
    },

    get disabledButtons() {
      return {
        submit: self.sending,
      };
    },

    get disabled() {
      return {
        fields: this.disabledFields,
        buttons: this.disabledButtons,
      };
    },
  }))

  .actions((self) => ({
    signIn: flow(function* signIn() {
      try {
        self.sending = true;
        const data = {
          username: self.values[fields.email],
          password: self.values[fields.password],
        };
        const result = yield apiDp.admin.signIn(data);
        self.root.store.onSignIn(result);
        alerts.add({ message: self.captions.success, type: 'success' });
      } catch (e) {
        alerts.add({ message: self.captions.fail, type: 'error' });
      } finally {
        self.sending = false;
      }
    }),

    authorize: flow(function* authorize(code) {
      try {
        self.reload = true;
        self.authorizing = true;
        const data = { code };
        const result = yield apiDp.admin.signInGoogle(data);
        self.root.store.onSignIn(result);
        alerts.add({ message: self.captions.success, type: 'success' });
      } catch (e) {
        alerts.add({ message: self.captions.fail, type: 'error' });
      } finally {
        self.authorizing = false;
      }
    }),
  }))

  .actions((self) => ({
    mount: () => {
      if (self.mounted) return;
      self.mounted = true;
    },

    unmount: () => {
      self.mounted = false;
      self.authorizing = false;
      self.reload = false;
      self.sending = false;
      self.data = { touched: {}, values: {} };
    },

    update: (location: Location) => {
      const query = queryString.parse(location.search);
      if (query?.code && query?.scope) self.authorize(query.code);
    },
  }))
  .actions((self) => {
    const handleBlur = ({ id }: InputEventParams) => {
      self.data.touched[id] = true;

      if (typeof self.data.values[id] === 'string') {
        const trimValue = self.data.values[id].trim().replace(/\s+/gi, ' ');
        if (trimValue !== self.data.values[id]) handleChange({ id, value: trimValue });
      }
    };

    const handleChange = ({ id, value }: InputEventParams) => {
      self.data.values[id] = value;
    };

    const handleSubmit = () => {
      self.data.touched = Object.values(fields).reduce((result, item) => ({ ...result, [item]: true }), {});
      if (self.hasErrors) return;
      self.signIn();
    };

    return {
      handleBlur,
      handleChange,
      handleSubmit,
    };
  });

export function create() {
  return Store.create({
    mounted: false,
    authorizing: false,
    reload: false,
    sending: false,
    data: { touched: {}, values: {} },
  });
}

export interface IStore extends Instance<typeof Store> {}
