import { roleSections } from 'api/dp';

export const paths = {
  home: '/',
  notFound: '/*',
  administration: {
    main: '/administration',
    roles: {
      main: '/administration/roles',
    },
    operators: {
      main: '/administration/operators',
      item: '/administration/operators/:id',
      create: '/administration/operators/create',
    },
    logs: {
      main: '/administration/logs',
    },
  },
  business: {
    main: '/business',
    stores: {
      main: '/business/stores',
      create: '/business/stores/create',
      item: '/business/stores/:id',
    },
    suppliers: {
      main: '/business/suppliers',
      create: '/business/suppliers/create',
      item: '/business/suppliers/:id',
    },
    products: {
      main: '/business/products',
      create: '/business/products/create',
      item: '/business/products/:id',
    },
    warehouse: {
      main: '/business/warehouse',
    },
  },
  orders: {
    main: '/orders',
    storeOrders: {
      main: '/orders/store-orders',
      create: '/orders/store-orders/create',
      item: '/orders/store-orders/:id',
    },
    orderManagement: {
      main: '/orders/order-management',
      create: '/orders/order-management/create',
      item: '/orders/order-management/:id',
    },
  },
  content: {
    main: '/content',
    articles: {
      main: '/content/articles',
      create: '/content/articles/create',
      item: '/content/articles/:id',
    },
    articlesManagement: {
      main: '/content/articles-management',
      create: '/content/articles-management/create',
      item: '/content/articles-management/:id',
    },
  },
};

export const captions = {
  pages: {
    home: 'Главная',
    notFound: 'Страница не найдена',
    administration: {
      main: 'Администрирование',
      roles: {
        main: 'Роли',
      },
      operators: {
        main: 'Операторы',
        item: '{:name}',
        create: 'Добавить оператора',
      },
      logs: {
        main: 'Логи',
      },
    },
    business: {
      main: 'Бизнес',
      stores: {
        main: 'Магазины',
        item: '{:name}',
        create: 'Добавить магазин',
      },
      suppliers: {
        main: 'Поставщики',
        item: '{:name}',
        create: 'Добавить поставщика',
      },
      products: {
        main: 'Товары',
        item: '{:name}',
        create: 'Добавить товар',
        remove: 'Удалить {:count} [:count(товар,товара,товаров)]',
      },
      warehouse: {
        main: 'Склад',
      },
    },
    orders: {
      main: 'Заказы',
      storeOrders: {
        main: 'Заказы от магазина',
        item: 'Заказ №{:name}',
        create: 'Добавить заказ',
      },
      orderManagement: {
        main: 'Управление заказами',
        item: '{:name}',
        create: 'Добавить',
      },
    },
    content: {
      main: 'Контент',
      articles: {
        main: 'Статьи',
        item: 'Статья №{:name}',
      },
      articlesManagement: {
        main: 'Управление контентом',
        item: 'Статья №{:name}',
        create: 'Добавить статью',
      },
    },
  },
  goTo: {
    home: 'Перейти на главную страницу',
  },
  actions: {
    signOut: 'Выйти',
    edit: 'Редактировать',
    remove: 'Удалить',
    find: 'Найти',
    reset: 'Сбросить',
    change: 'Изменить',
    cancel: 'Отмена',
  },
  filter: {
    title: 'Фильтр',
  },
  errors: {
    default: 'Возникла ошибка. Мы, скорее всего, уже работаем над исправлением. Можете уточнить у службы поддержки.',
    404: 'Искомый ресурс не найден',
  },
};

export const pages = {
  home: {
    id: 'home',
    path: paths.home,
    title: captions.pages.home,
    goTo: captions.goTo.home,
  },
  notFound: {
    id: 'notFound',
    path: paths.notFound,
    title: captions.pages.notFound,
  },
  administration: {
    main: {
      id: 'administration',
      path: paths.administration.main,
      title: captions.pages.administration.main,
    },
    roles: {
      main: {
        id: 'roles',
        path: paths.administration.roles.main,
        title: captions.pages.administration.roles.main,
      },
    },
    operators: {
      main: {
        id: 'operators',
        path: paths.administration.operators.main,
        title: captions.pages.administration.operators.main,
      },
      create: {
        id: 'operatorsCreate',
        path: paths.administration.operators.create,
        title: captions.pages.administration.operators.create,
      },
      item: {
        id: 'operatorsItem',
        path: paths.administration.operators.item,
        title: captions.pages.administration.operators.item,
      },
    },
    logs: {
      main: {
        id: 'logs',
        path: paths.administration.logs.main,
        title: captions.pages.administration.logs.main,
      },
    },
  },
  business: {
    main: {
      id: 'business',
      path: paths.business.main,
      title: captions.pages.business.main,
    },
    stores: {
      main: {
        id: 'stores',
        path: paths.business.stores.main,
        title: captions.pages.business.stores.main,
      },
      create: {
        id: 'storesCreate',
        path: paths.business.stores.create,
        title: captions.pages.business.stores.create,
      },
      item: {
        id: 'storesItem',
        path: paths.business.stores.item,
        title: captions.pages.business.stores.item,
      },
    },
    suppliers: {
      main: {
        id: 'suppliers',
        path: paths.business.suppliers.main,
        title: captions.pages.business.suppliers.main,
      },
      create: {
        id: 'suppliersCreate',
        path: paths.business.suppliers.create,
        title: captions.pages.business.suppliers.create,
      },
      item: {
        id: 'suppliersItem',
        path: paths.business.suppliers.item,
        title: captions.pages.business.suppliers.item,
      },
    },
    products: {
      main: {
        id: 'products',
        path: paths.business.products.main,
        title: captions.pages.business.products.main,
      },
      create: {
        id: 'productsCreate',
        path: paths.business.products.create,
        title: captions.pages.business.products.create,
      },
      item: {
        id: 'productsItem',
        path: paths.business.products.item,
        title: captions.pages.business.products.item,
      },
    },
    warehouse: {
      main: {
        id: 'warehouse',
        path: paths.business.warehouse.main,
        title: captions.pages.business.warehouse.main,
      },
    },
  },
  orders: {
    main: {
      id: 'orders',
      path: paths.orders.main,
      title: captions.pages.orders.main,
    },
    storeOrders: {
      main: {
        id: 'storeOrders',
        path: paths.orders.storeOrders.main,
        title: captions.pages.orders.storeOrders.main,
      },
      create: {
        id: 'storeOrdersCreate',
        path: paths.orders.storeOrders.create,
        title: captions.pages.orders.storeOrders.create,
      },
      item: {
        id: 'storeOrdersItem',
        path: paths.orders.storeOrders.item,
        title: captions.pages.orders.storeOrders.item,
      },
    },
    orderManagement: {
      main: {
        id: 'orderManagement',
        path: paths.orders.orderManagement.main,
        title: captions.pages.orders.orderManagement.main,
      },
      create: {
        id: 'orderManagementCreate',
        path: paths.orders.orderManagement.create,
        title: captions.pages.orders.orderManagement.create,
      },
      item: {
        id: 'orderManagementItem',
        path: paths.orders.orderManagement.item,
        title: captions.pages.orders.orderManagement.item,
      },
    },
  },
  content: {
    main: {
      id: 'content',
      path: paths.content.main,
      title: captions.pages.content.main,
    },
    articles: {
      main: {
        id: 'articles',
        path: paths.content.articles.main,
        title: captions.pages.content.articles.main,
      },
      item: {
        id: 'articlesItem',
        path: paths.content.articles.item,
        title: captions.pages.content.articles.item,
      },
    },
    articlesManagement: {
      main: {
        id: 'articlesManagement',
        path: paths.content.articlesManagement.main,
        title: captions.pages.content.articlesManagement.main,
      },
      create: {
        id: 'articlesManagementCreate',
        path: paths.content.articlesManagement.create,
        title: captions.pages.content.articlesManagement.create,
      },
      item: {
        id: 'articlesManagementItem',
        path: paths.content.articlesManagement.item,
        title: captions.pages.content.articlesManagement.item,
      },
    },
  },
};

export const permissions = {
  [pages.home.id]: Object.values(roleSections),
  [pages.notFound.id]: Object.values(roleSections),

  [pages.administration.main.id]: [roleSections.admin],
  [pages.administration.roles.main.id]: [roleSections.admin],
  [pages.administration.operators.main.id]: [roleSections.admin],
  [pages.administration.operators.create.id]: [roleSections.admin],
  [pages.administration.operators.item.id]: [roleSections.admin],
  [pages.administration.logs.main.id]: [roleSections.admin],

  [pages.business.main.id]: [roleSections.admin, roleSections.store, roleSections.supplier, roleSections.product],

  [pages.business.stores.main.id]: [roleSections.admin, roleSections.store],
  [pages.business.stores.create.id]: [roleSections.admin, roleSections.store],
  [pages.business.stores.item.id]: [roleSections.admin, roleSections.store],

  [pages.business.suppliers.main.id]: [roleSections.admin, roleSections.supplier],
  [pages.business.suppliers.create.id]: [roleSections.admin, roleSections.supplier],
  [pages.business.suppliers.item.id]: [roleSections.admin, roleSections.supplier],

  [pages.business.products.main.id]: [roleSections.admin, roleSections.product],
  [pages.business.products.create.id]: [roleSections.admin, roleSections.product],
  [pages.business.products.item.id]: [roleSections.admin, roleSections.product],

  [pages.business.warehouse.main.id]: [roleSections.admin],

  [pages.orders.main.id]: [roleSections.admin, roleSections.order, roleSections.supplierOrder],

  [pages.orders.storeOrders.main.id]: [roleSections.admin, roleSections.order],
  [pages.orders.storeOrders.create.id]: [roleSections.admin, roleSections.order],
  [pages.orders.storeOrders.item.id]: [roleSections.admin, roleSections.order],

  [pages.orders.orderManagement.main.id]: [roleSections.admin, roleSections.supplierOrder],
  [pages.orders.orderManagement.create.id]: [roleSections.admin, roleSections.supplierOrder],
  [pages.orders.orderManagement.item.id]: [roleSections.admin, roleSections.supplierOrder],

  [pages.content.main.id]: [roleSections.admin, roleSections.contentCreate, roleSections.contentView],

  [pages.content.articles.main.id]: [roleSections.admin, roleSections.contentView],
  [pages.content.articles.item.id]: [roleSections.admin, roleSections.contentView],

  [pages.content.articlesManagement.main.id]: [roleSections.admin, roleSections.contentCreate],
  [pages.content.articlesManagement.create.id]: [roleSections.admin, roleSections.contentCreate],
  [pages.content.articlesManagement.item.id]: [roleSections.admin, roleSections.contentCreate],
};

export default {
  paths,
  captions,
  pages,
  permissions,
};
