import { CancelTokenSource } from 'axios';

import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { ArticleDto, GetArticlesParams } from './types';

function getArticles(params: GetArticlesParams, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    cancelToken: cancel.token,
    method: 'GET',
    url: urls.getArticles,
    params,
  });
}

function getArticle(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getArticle.replace(':id', id),
  });
}

function createArticle(data: ArticleDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createArticle,
    data,
  });
}

function updateArticle(id: string, data: ArticleDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateArticle.replace(':id', id),
    data,
  });
}

function removeArticle(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeArticle.replace(':id', id),
  });
}

export default {
  getArticles,
  getArticle,
  createArticle,
  updateArticle,
  removeArticle,
};
