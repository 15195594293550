import styled from 'styled-components';

import getBrowser from 'utils/getBrowser';

import { StyledInputElement } from 'components/inputs/style';

import { StyledInputTextProps } from './types';

export function getInputPasswordType(hidden: boolean, autoComplete: boolean) {
  const browser = getBrowser();
  if (autoComplete && hidden) return 'password';
  if (!hidden) return 'text';
  if (['Safari', 'Chrome'].includes(browser)) return 'text';
  return 'password';
}

export function getInputPasswordAutoComplete(hidden: boolean, autoComplete: boolean) {
  if (autoComplete) return 'on';
  return hidden ? 'new-password' : 'off';
}

export const StyledInputPassword = styled(StyledInputElement).attrs<StyledInputTextProps>(
  ({ sHidden, sAutoComplete }) => ({
    type: getInputPasswordType(sHidden, sAutoComplete),
    autoComplete: getInputPasswordAutoComplete(sHidden, sAutoComplete),
    spellCheck: 'false',
    autoCorrect: 'off',
  }),
)<StyledInputTextProps>`
  -webkit-text-security: ${({ sHidden }) => (sHidden ? 'disc' : 'none')};
`;
