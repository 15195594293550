import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import {
  GetSupplierOrdersGroupsParams,
  GetSupplierOrdersParams,
  SupplierOrderDto,
  SupplierOrdersDto,
  GetPreviewParams,
} from './types';

function previewSupplierOrder(params: GetPreviewParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.previewSupplierOrder,
    params,
  });
}
function getSupplierOrders(params: GetSupplierOrdersParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getSupplierOrders,
    params,
  });
}

function getSupplierOrder(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getSupplierOrder.replace(':id', id),
  });
}

function createSupplierOrder(data: SupplierOrderDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createSupplierOrder,
    data,
  });
}

function createSupplierOrders(data: SupplierOrdersDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createSupplierOrders,
    data,
  });
}

function updateSupplierOrder(id: string, data: SupplierOrderDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateSupplierOrder.replace(':id', id),
    data,
  });
}

function updateSupplierOrders(id: string, data: SupplierOrdersDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateSupplierOrders.replace(':id', id),
    data,
  });
}

function updateSupplierOrdersStatus(id: string, status: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateSupplierOrdersStatus.replace(':id', id).replace(':status', status),
  });
}

function removeSupplierOrder(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeSupplierOrder.replace(':id', id),
  });
}

function getSupplierOrdersGroups(params: GetSupplierOrdersGroupsParams) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getSupplierOrdersGroups,
    params,
  });
}

function getSupplierOrdersGroup(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getSupplierOrdersGroup.replace(':id', id),
  });
}

function getSupplierOrdersDraft() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getSupplierOrdersDraft,
  });
}

export default {
  previewSupplierOrder,
  getSupplierOrders,
  getSupplierOrder,
  createSupplierOrder,
  createSupplierOrders,
  updateSupplierOrder,
  updateSupplierOrders,
  updateSupplierOrdersStatus,
  removeSupplierOrder,
  getSupplierOrdersGroups,
  getSupplierOrdersGroup,
  getSupplierOrdersDraft,
};
