import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { CancelTokenSource } from 'axios';
import { getCancelToken } from '../../axios';

let cancelGetStores: CancelTokenSource = null;
let cancelGetStoreProducts: CancelTokenSource = null;

export const Store = types.model().actions(() => ({
  getStores: flow(function* request(params) {
    cancelGetStores = getCancelToken(cancelGetStores);
    const response = yield requests.getStores(params, cancelGetStores);
    return response?.data;
  }),

  getStore: flow(function* request(id) {
    const response = yield requests.getStore(id);
    return response?.data;
  }),

  createStore: flow(function* request(data) {
    const response = yield requests.createStore(data);
    return response?.data;
  }),

  updateStore: flow(function* request(id, data) {
    const response = yield requests.updateStore(id, data);
    return response?.data;
  }),

  removeStore: flow(function* request(id) {
    const response = yield requests.removeStore(id);
    return response?.data;
  }),

  getWarehouse: flow(function* request() {
    const response = yield requests.getWarehouse();
    return response?.data;
  }),

  saveStoreProducts: flow(function* request(data) {
    const response = yield requests.saveStoreProducts(data);
    return response?.data;
  }),

  getStoreProducts: flow(function* request(id, params) {
    cancelGetStoreProducts = getCancelToken(cancelGetStoreProducts);
    const response = yield requests.getStoreProducts(id, params, cancelGetStoreProducts);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
