export const urls = {
  getSuppliers: '/api/v1/suppliers',
  getSupplier: '/api/v1/suppliers/:id',
  createSupplier: '/api/v1/suppliers',
  updateSupplier: '/api/v1/suppliers/:id',
  removeSupplier: '/api/v1/suppliers/:id',
};

export const suppliersSortBy = {
  id: 'id',
  name: 'name',
  entityCode: 'entityCode',
  phone: 'phone',
  productCount: 'productCount',
};
