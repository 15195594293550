import { AxiosRequestConfig } from 'axios';

import { logRequest } from 'api/utils';

import { urls } from '../constants';

import { previewResponse, suppliersOrdersResponse } from './data';

const jsonHeaders = { 'content-type': 'application/json' };

const ordersList = (config: AxiosRequestConfig) => logRequest(config, [200, suppliersOrdersResponse, jsonHeaders]);
const preview = (config: AxiosRequestConfig) => logRequest(config, [200, previewResponse, jsonHeaders]);

export default function mockAdapter(axiosMockInstance: any) {
  axiosMockInstance.onGet(urls.previewSupplierOrder).reply(preview);
  axiosMockInstance.onGet(urls.getSupplierOrders).reply(ordersList);
}
