import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import {
  CreateOrderFromPostponedDto,
  CreateOrdersFromPostponedDto,
  GetPostponedOrdersParams,
  PostponedOrderDto,
} from './types';
import { CancelTokenSource } from 'axios';

function getPostponedOrders(params: GetPostponedOrdersParams, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    cancelToken: cancel.token,
    method: 'GET',
    url: urls.getPostponedOrders,
    params,
  });
}

function addPostponedOrder(data: PostponedOrderDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.addPostponedOrder,
    data,
  });
}

function createOrder(data: CreateOrderFromPostponedDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createOrder,
    data,
  });
}

function createOrders(data: CreateOrdersFromPostponedDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createOrders,
    data,
  });
}

export default {
  getPostponedOrders,
  addPostponedOrder,
  createOrder,
  createOrders,
};
