import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { CancelTokenSource } from 'axios';
import { getCancelToken } from '../../axios';

let cancelGetProductCategories: CancelTokenSource = null;

export const Store = types.model().actions(() => ({
  getProductCategories: flow(function* request(params) {
    cancelGetProductCategories = getCancelToken(cancelGetProductCategories);
    const response = yield requests.getProductCategories(params, cancelGetProductCategories);
    return response?.data;
  }),

  createProductCategory: flow(function* request(data) {
    const response = yield requests.createProductCategory(data);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
