export const urls = {
  getOrders: '/api/v1/orders',
  getOrder: '/api/v1/orders/:id',
  createOrder: '/api/v1/orders',
  updateOrder: '/api/v1/orders/:id',
  removeOrder: '/api/v1/orders/:id',
  updateOrderItems: '/api/v1/orders/items/bulk',
  getProductOrdersHistory: '/api/v1/orders/orders/:productId',
  checkOrder: '/api/v1/orders/check',
};
