import React from 'react';

import { IconProps } from './types';
import IconPrimitive from './_internal/IconPrimitive';

const IconEyeClosed: React.FC<IconProps> = (props) => {
  return (
    <IconPrimitive {...props} viewBox={24}>
      <path d="M20.6487,3.27722 C20.9675,3.61601 20.9941,4.14852 20.7284,4.51959 L20.6487,4.61652 L4.55408,21.7228 C4.20635,22.0924 3.64171,22.0924 3.29398,21.7228 C2.97519,21.384 2.94862,20.8515 3.21428,20.4804 L3.29398,20.3835 L19.3886,3.27722 C19.7363,2.90759 20.301,2.90759 20.6487,3.27722 Z M19.4472,7.59354 C20.9581,8.64222 22.3544,9.94666 23.6075,11.3798 C24.1339,12.0112 24.1333,12.961 23.5907,13.6099 C20.4809,17.151 16.467,19.9099 12.0063,19.9099 C10.7732,19.9099 9.57366,19.699 8.41728,19.3186 L9.95816,17.6796 C10.6329,17.8333 11.317,17.9156 12.0063,17.9156 C15.7157,17.9156 19.2661,15.5341 22.0098,12.4849 C20.8284,11.1682 19.5018,9.97751 18.0801,9.04644 L19.4472,7.59354 Z M11.9998,5.02959 C13.2309,5.02959 14.4283,5.24072 15.5823,5.62157 L14.0419,7.25986 C13.3692,7.10623 12.6872,7.02398 11.9998,7.02398 C8.29092,7.02398 4.73751,9.418 1.9905,12.4829 C3.17097,13.7903 4.49579,14.9727 5.91514,15.8981 L4.54774,17.3516 C3.04062,16.3098 1.64702,15.0151 0.395693,13.5929 C-0.133059,12.9626 -0.13462,12.0116 0.407417,11.3614 C3.52066,7.80262 7.53738,5.02959 11.9998,5.02959 Z M15.4893,11.8033 C15.5257,12.0197 15.5448,12.2424 15.5448,12.4698 C15.5448,14.5458 13.9596,16.2306 12.0063,16.2306 C11.7921,16.2306 11.5822,16.2103 11.3784,16.1715 L15.4893,11.8033 Z M12.0063,8.70894 C12.2173,8.70894 12.4239,8.72858 12.6247,8.76625 L8.52176,13.1266 C8.48636,12.9133 8.4679,12.6938 8.4679,12.4698 C8.4679,10.3937 10.0531,8.70894 12.0063,8.70894 Z" />
    </IconPrimitive>
  );
};

export default IconEyeClosed;
