import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { Header, Content } from 'components/molecules/Layout';
import Block from 'components/atoms/Block';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import InputText from 'components/inputs/InputText';
import InputPassword from 'components/inputs/InputPassword';
import Grid, { Item } from 'components/atoms/Grid';
import Form, { Field } from 'components/atoms/Form';

import { ComponentProps } from './types';

import { fields } from './constants';

const SignIn: React.FC<ComponentProps> = (props) => {
  const { store } = props;
  const {
    mounted,
    mount,
    unmount,
    update,
    authorizing,
    reload,
    captions,
    values,
    errors,
    disabled,
    handleBlur,
    handleChange,
    handleSubmit,
    signInRedirectUrl,
  } = store;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!mounted) mount();

    return () => {
      if (mounted) unmount();
    };
  }, [mounted]);

  useEffect(() => {
    if (mounted) update(location);
  }, [mounted, location]);

  useEffect(() => {
    if (reload) navigate(location.pathname, { replace: true });
  }, [reload]);

  if (!mounted) return null;

  return (
    <>
      <Header title={captions.title} />
      <Content width="s">
        {authorizing && (
          <Block margin="m" textAlign="center">
            {captions.authorizing}
          </Block>
        )}
        {!authorizing && (
          <Form onSubmit={handleSubmit}>
            <Block vPadding="l" textAlign="center">
              <Grid size={6} spacing="s">
                <Item size={6}>
                  <Field id={fields.email} error={errors[fields.email]}>
                    <InputText
                      id={fields.email}
                      label={captions.fields[fields.email]}
                      value={values[fields.email]}
                      error={!!errors[fields.email]}
                      disabled={disabled.fields[fields.email]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Field>
                </Item>
                <Item size={6}>
                  <Field id={fields.password} error={errors[fields.password]}>
                    <InputPassword
                      id={fields.password}
                      label={captions.fields[fields.password]}
                      value={values[fields.password]}
                      error={!!errors[fields.password]}
                      disabled={disabled.fields[fields.password]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete
                    />
                  </Field>
                </Item>
              </Grid>
              <Block margin="m">
                <Button type="primary" disabled={disabled.buttons.submit} display="block" onClick={handleSubmit}>
                  {captions.buttons.signIn}
                </Button>
              </Block>
            </Block>
            <Block>
              <Link external block href={signInRedirectUrl}>
                <Button type="link" display="block">
                  {captions.buttons.signInGoogle}
                </Button>
              </Link>
            </Block>
          </Form>
        )}
      </Content>
    </>
  );
};

export default observer(SignIn);
