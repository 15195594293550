export const urls = {
  getProducts: '/api/v1/products',
  getProduct: '/api/v1/products/:id',
  createProduct: '/api/v1/products',
  updateProduct: '/api/v1/products/:id',
  removeProduct: '/api/v1/products/:id',
  removeProducts: '/api/v1/products/bulk',
  updateProducts: '/api/v1/products/bulk',
  getBalance: '/api/v1/products/balance/:storeId',
};
