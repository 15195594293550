import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from 'axios';

import { createInstance } from 'api/axios';

import { urls } from './constants';
import { getAccessToken, getRefreshToken, setTokens, removeTokens } from './utils';

export type RefreshTokenRequestData = Readonly<{
  refreshToken?: string | null;
}>;

export type RefreshSubscriber = Readonly<{
  resolve: (token?: string) => void;
  reject: (error?: AxiosError) => void;
}>;

export type CustomAxiosRequestConfig = AxiosRequestConfig & { retry: boolean };

let apiInstance: AxiosInstance;
let isRefreshing = false;
let refreshSubscribers: RefreshSubscriber[] = [];

function processQueue(error?: AxiosError, token?: string) {
  refreshSubscribers.forEach((item) => {
    if (error) {
      item.reject(error);
    } else {
      item.resolve(token);
    }
  });

  refreshSubscribers = [];
}

function refreshTokenRequest(data: RefreshTokenRequestData) {
  if (!data.refreshToken) throw new Error('Do not have new token');

  return apiInstance({
    method: 'POST',
    url: urls.refreshToken,
    data,
  });
}

export async function refreshToken() {
  try {
    const params = {
      refreshToken: getRefreshToken(),
    };
    const result = await refreshTokenRequest(params);
    if (!result?.data?.ok) {
      removeTokens();
      throw new Error('Do not receive new token');
    }
    setTokens(result?.data);
    return result?.data?.accessToken;
  } catch (e) {
    const error = e as AxiosError;
    if (error?.response?.status === 401) removeTokens();
    throw new Error('Do not receive new token');
  }
}

function handleReject(error: AxiosError) {
  if (!error?.response) return Promise.reject(error);

  const { config, response } = error;

  const originalRequest = config as CustomAxiosRequestConfig;

  if (response?.status === 401 && !originalRequest.retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshSubscribers.push({ resolve, reject });
      })
        .then((token) => {
          if (originalRequest?.headers) originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest.retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      refreshToken()
        .then((newToken) => {
          if (originalRequest?.headers) originalRequest.headers.Authorization = `Bearer ${newToken}`;
          processQueue(undefined, newToken);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, undefined);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
}

export function configureApiInstance() {
  apiInstance = createInstance(process.env.REACT_APP_DP_API_HOST);

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => handleReject(error),
  );
}

export function getApiInstance() {
  return apiInstance;
}

export function getAuthHeaders() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

configureApiInstance();
