export const urls = {
  previewSupplierOrder: '/api/v1/supplier-orders/preview',
  getSupplierOrders: '/api/v1/supplier-orders',
  getSupplierOrder: '/api/v1/supplier-orders/:id',
  createSupplierOrder: '/api/v1/supplier-orders',
  updateSupplierOrder: '/api/v1/supplier-orders/:id',
  updateSupplierOrders: '/api/v1/supplier-orders/groups/:id',
  updateSupplierOrdersStatus: '/api/v1/supplier-orders/groups/:id/status/:status',
  removeSupplierOrder: '/api/v1/supplier-orders/:id',
  createSupplierOrders: '/api/v1/supplier-orders/bulk',
  getSupplierOrdersGroups: '/api/v1/supplier-orders/groups',
  getSupplierOrdersGroup: '/api/v1/supplier-orders/:id/group',
  getSupplierOrdersDraft: '/api/v1/supplier-orders/draft',
};
