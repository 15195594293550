import { types, Instance } from 'mobx-state-tree';
import { Shop } from '../stores/models';

export const OperatorRolePermission = types.model({
  id: types.maybeNull(types.number),
  roleId: types.maybeNull(types.number),
  section: types.maybeNull(types.string),
});

export interface IOperatorRolePermission extends Instance<typeof OperatorRolePermission> {}

export const OperatorRole = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  permissions: types.maybeNull(types.array(OperatorRolePermission)),
});

export interface IOperatorRole extends Instance<typeof OperatorRole> {}

export const StoreEmployee = types.model({
  id: types.maybeNull(types.number),
  storeId: types.maybeNull(types.number),
  operatorId: types.maybeNull(types.number),
  store: types.maybeNull(Shop),
});

export interface IStoreEmployee extends Instance<typeof StoreEmployee> {}

export const StoreEmployees = types.array(StoreEmployee);

export interface IStoreEmployees extends Instance<typeof StoreEmployees> {}

export const Operator = types.model({
  id: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  avatarSrc: types.maybeNull(types.string),
  isAdmin: types.maybeNull(types.boolean),
  isSuperAdmin: types.maybeNull(types.boolean),
  roleId: types.maybeNull(types.number),
  role: types.maybeNull(OperatorRole),
  createdAt: types.maybeNull(types.string),
  storeEmployees: types.maybeNull(StoreEmployees),
});

export interface IOperator extends Instance<typeof Operator> {}

export const LogsItem = types.model({
  id: types.maybeNull(types.number),
  action: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  ip: types.maybeNull(types.string),
  operatorId: types.maybeNull(types.number),
  meta: types.maybeNull(types.frozen()),
  payload: types.maybeNull(types.frozen()),
});

export interface ILogsItem extends Instance<typeof LogsItem> {}

export const SignInResponse = types.model({
  accessToken: types.string,
  refreshToken: types.string,
});

export interface ISignInResponse extends Instance<typeof SignInResponse> {}
