import React, { useState, useEffect, useRef, useMemo } from 'react';

import IconActionClear from 'components/icons/IconActionClear';
import IconStatusSuccess from 'components/icons/IconStatusSuccess';
import IconEyeOpen from 'components/icons/IconEyeOpen';
import IconEyeClosed from 'components/icons/IconEyeClosed';
import { StyledInput, StyledInputLabel, StyledInputIcon } from 'components/inputs/style';
import { getCaretPosition } from 'components/inputs/utils';

import { InputTextProps } from './types';
import { formatTextValue, deformatTextValue } from './utils';
import { StyledInputPassword } from './style';

const InputPassword: React.FC<InputTextProps> = (props) => {
  const {
    id,
    size = 'm',
    error = false,
    disabled = false,
    onFocus = () => null,
    onBlur = () => null,
    onChange = () => null,
    value,
    label,
    width = '100%',
    autoFocus = false,
    success = false,
    clearable = false,
    textAlign = 'left',
    autoComplete = false,
    maxLength,
    format = formatTextValue,
    deformat = deformatTextValue,
    inputMode = 'text',
  } = props;

  const [hidden, setHidden] = useState(true);
  const [focused, setFocused] = useState(false);
  const [prevValue, setPrevValue] = useState(null);
  const [formattedValue, setFormattedValue] = useState('');
  const [caretPosition, setCaretPosition] = useState(null);
  const [disallowBlurFlag, setDisallowBlurFlag] = useState(false);

  const inputNode = useRef(null);

  const handleFocus = () => {
    setFocused(true);
    onFocus({ id, value, formattedValue });
  };

  const handleBlur = () => {
    if (disallowBlurFlag) return;
    setFocused(false);
    setCaretPosition(null);
    onBlur({ id, value, formattedValue });
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
    const eValue = e.currentTarget.value;

    const fValue = format(eValue, maxLength);
    const dfValue = deformat(fValue);

    const beforeCaretPosValue = deformat(eValue.substring(0, e.currentTarget.selectionEnd));
    setCaretPosition(getCaretPosition(fValue, beforeCaretPosValue, 0));
    setPrevValue(dfValue);
    setFormattedValue(fValue);

    onChange({ id, value: dfValue, formattedValue: fValue });
  };

  const handleClear = () => {
    const fValue = format('', maxLength);
    const dfValue = deformat(fValue);
    setPrevValue(dfValue);
    setFormattedValue(fValue);

    onChange({ id, value: dfValue, formattedValue: fValue });
  };

  const handleKeyDown = () => {
    setCaretPosition(null);
  };

  const disallowBlur = () => {
    setDisallowBlurFlag(true);
  };

  const allowBlur = () => {
    if (disallowBlurFlag) {
      setDisallowBlurFlag(false);
      inputNode.current.focus();
    }
  };

  const handleToggleHidden = () => {
    if (!disabled) setHidden(!hidden);
  };

  useEffect(() => {
    if (autoFocus) inputNode.current.focus();
  }, [autoFocus]);

  useEffect(() => {
    if (disabled && focused) handleBlur();
  }, [disabled]);

  useEffect(() => {
    if (value !== prevValue) {
      setFormattedValue(format(value, maxLength));
      setPrevValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (caretPosition !== null) {
      inputNode.current.setSelectionRange(caretPosition, caretPosition);
    }
  });

  const smallLabel = useMemo(() => focused || !!value, [focused, value]);
  const successIcon = useMemo(() => success && !disabled && !focused, [success, disabled, focused]);
  const clearableIcon = useMemo(
    () => clearable && !!value && focused && !disabled,
    [clearable, value, focused, disabled],
  );
  const withIcon = useMemo(() => successIcon || clearableIcon, [successIcon, clearableIcon]);
  const eyeClosedIcon = useMemo(() => (hidden || disabled) && focused, [hidden, disabled, focused]);
  const eyeOpenIcon = useMemo(() => !hidden && !disabled && focused, [hidden, disabled, focused]);

  return (
    <StyledInput sWidth={width}>
      <StyledInputPassword
        ref={inputNode}
        as="input"
        inputMode={inputMode}
        id={id}
        value={formattedValue}
        maxLength={maxLength}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        sSize={size}
        sTextAlign={textAlign}
        sAutoComplete={autoComplete}
        sDisabled={disabled}
        sFocused={focused}
        sError={error}
        sWithLabel={!!label && smallLabel}
        sWithIcon={withIcon}
        sHidden={hidden || disabled}
      />
      {label && (
        <StyledInputLabel sSize={size} sSmall={smallLabel} sError={error} sDisabled={disabled} sFocused={focused}>
          {label}
        </StyledInputLabel>
      )}
      {eyeClosedIcon && (
        <StyledInputIcon
          sSize={size}
          onMouseDown={disallowBlur}
          onMouseUp={allowBlur}
          onClick={handleToggleHidden}
          sClickable
        >
          <IconEyeClosed size={size} display="block" />
        </StyledInputIcon>
      )}
      {eyeOpenIcon && (
        <StyledInputIcon
          sSize={size}
          onMouseDown={disallowBlur}
          onMouseUp={allowBlur}
          onClick={handleToggleHidden}
          sClickable
        >
          <IconEyeOpen size={size} display="block" />
        </StyledInputIcon>
      )}
      {successIcon && (
        <StyledInputIcon sSize={size}>
          <IconStatusSuccess display="block" color="success" size={size} />
        </StyledInputIcon>
      )}
      {clearableIcon && (
        <StyledInputIcon
          sSize={size}
          sClickable
          sDisabled={disabled}
          onMouseDown={disallowBlur}
          onMouseUp={allowBlur}
          onClick={handleClear}
        >
          <IconActionClear display="block" size={size} />
        </StyledInputIcon>
      )}
    </StyledInput>
  );
};

export default InputPassword;
