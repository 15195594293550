import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { CancelTokenSource } from 'axios';
import { getCancelToken } from '../../axios';

let cancelGetArticles: CancelTokenSource = null;

export const Store = types.model().actions(() => ({
  getArticles: flow(function* request(params) {
    cancelGetArticles = getCancelToken(cancelGetArticles);
    const response = yield requests.getArticles(params, cancelGetArticles);
    return response?.data;
  }),

  getArticle: flow(function* request(id) {
    const response = yield requests.getArticle(id);
    return response?.data;
  }),

  createArticle: flow(function* request(data) {
    const response = yield requests.createArticle(data);
    return response?.data;
  }),

  updateArticle: flow(function* request(id, data) {
    const response = yield requests.updateArticle(id, data);
    return response?.data;
  }),

  removeArticle: flow(function* request(id) {
    const response = yield requests.removeArticle(id);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
