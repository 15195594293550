import { getApiInstance, getAuthHeaders } from '../instance';

import { urls } from './constants';
import { GetSuppliersParams, SupplierDto } from './types';
import { CancelTokenSource } from 'axios';

function getSuppliers(params: GetSuppliersParams, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    cancelToken: cancel.token,
    method: 'GET',
    url: urls.getSuppliers,
    params,
  });
}

function getSupplier(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: urls.getSupplier.replace(':id', id),
  });
}

function createSupplier(data: SupplierDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: urls.createSupplier,
    data,
  });
}

function updateSupplier(id: string, data: SupplierDto) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: urls.updateSupplier.replace(':id', id),
    data,
  });
}

function removeSupplier(id: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: urls.removeSupplier.replace(':id', id),
  });
}

export default {
  getSuppliers,
  getSupplier,
  createSupplier,
  updateSupplier,
  removeSupplier,
};
